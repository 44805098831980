/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Checkbox, Loader, Switch, TextInput, ErrorSummary, EmailInput } from '@ux/balance-react';
// @ts-ignore
import { IconEditPencil, IconTrash } from '@ux/balance-icons';
import { useEffect, useState, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import CorpCodes from '../API_Integrations/CorpCodes';
import Lockbox from '../API_Integrations/Lockbox';
import { CreateEditUser, createUser, deleteUser, editUser, getUser, getUsers, User, generateUserEntitlementReport } from "../API_Integrations/users";
import { Client_code, usersState, userState } from "../atom";
import ButtonContainer from '../components/ButtonContainer';
import ClientHeader from '../components/ClientHeader';
import CustomModal from '../components/CustomModal';
import DateDisplay from '../components/DateDisplay';
import ModalsContainer from '../components/ModalsContainer';
import Table from '../components/Table';
import { ICorpCodes } from '../interfaces/ICorpCodes';
import { ILockbox, ILockbox_req_body } from '../interfaces/iLockBox';
import { useTitle } from '../utils/use-title';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import CurrentUser from '../API_Integrations/CurrentUser';
interface ErrorMessage { ariaLabel: string, text: string, inputId: string }

const UserManagement = () => {
    useTitle("User management");
    enum UserMangeState { CurrentUsers, EditUsers, AddUsers };
    const userType = useRecoilValue(userState);
    const client_Id = useRecoilValue(Client_code);
    const [edit_user_Id, setEdit_user_Id] = useState(0);
    const [users, setUsers] = useRecoilState(usersState);
    const [delete_User_Id, setDelete_User_Id] = useState(0);
    const [loading, setLoding] = useState(true);
    const [manageState, setManageState] = useState(UserMangeState.CurrentUsers);
    const [userCreateOrEdit, setUserCreateOrEdit] = useState<CreateEditUser>({ clientCode: client_Id, username: '', isAdmin: false, isBanker: false, firstName: '', middleName: '', lastName: '', emailAddress: '', status: '', canAddUsers: false, canModifyUsers: false, canChangeUserStatuses: false, canViewUsers: false, canManageBanners: false, canManageExceptions: false, canUnlockExceptions: false, lockboxCodes: [], corpCodes: [] });
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [checkedAllLocations, setCheckedAllLocations] = useState(false);
    const [locationOptions, setLocationOptions] = useState<{ id: string, label: string, code: string }[]>([]);
    const [checkedAllCorpCodes, setCheckedAllCorpCodes] = useState(false);
    const [selectedCorpCodes, setSelectedCorpCodes] = useState<string[]>([]);
    const [corpCodesOptions, setCorpCodesOptions] = useState<{ id: string, label: string, code: string }[]>([]);
    const [showSaveAddUserModal, setShowSaveAddUserModal] = useState(false);
    const [showSaveEditUserModal, setShowSaveEditUserModal] = useState(false);
    const [showSaveErrorModal, setShowSaveErrorModal] = useState(false);
    const [errorMessagetitle, setErrorMessagetitle] = useState('');
    const [errorMessagetext, setErrorMessagetext] = useState('');
    const [modalIsOpenDownload, setModalIsOpenDownload] = useState(false);
    const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false);
    const [errorMessagesSummary, seterrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [isvalidEmail, setIsvalidEmail] = useState(true);
    const [emailMessage, setEmailMessage] = useState([""]);
    const [isvalidUserName, setIsvalidUserName] = useState(true);
    const [isvalidLastName, setIsvalidLastName] = useState(true);
    const errorSummaryRef = useRef<HTMLElement | null>(null);
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);

    useFocusOnElemnt(initialTextRef);

    const getLockboxData = async (ReqBody: ILockbox_req_body) => {
        let result = await Lockbox.listClientLockbox(ReqBody)
        if (result !== undefined) {
            return result;
        } else {
            console.log('item not found')
        }
    }
    const getLockboxOptions = (options: ILockbox[]) => {
        let LockboxOptions = options.map((option) => ({
            id: option.code,
            code: option.code,
            label: `${option.code} ${option.type !== undefined ? `- ${option.type}` : ''} ${option.siteName !== undefined ? `${option.siteName}` : ''}`
        }));
        setLocationOptions(LockboxOptions);
        return LockboxOptions;
    };
    const getCorpCodesData = async () => {
        let result = await CorpCodes.list();
        if (result !== undefined) {
            return result;
        } else {
            console.log('item not found')
        }
    }
    const fetchData = async () => {
        return await getUsers();
    }
    const fetchSingleUser = async (ID: number) => {
        return await getUser(ID);
    }

    useEffect(() => {
        fetchData().then(users => {
            const updatedUsers = users.map((user: User) => ({
                ...user, rowClasses: user.isAdmin ? 'highlighted' : '',
            }))
            setUsers(updatedUsers);
            setLoding(false);
        });
        getLockboxData({
            lockboxNumber: '',
            lockboxName: ''
        }).then((result) => {
            getLockboxOptions(result);
        });
        getCorpCodesData().then((result) => {
            let CorpCodesOptions = result.map((el: ICorpCodes) => ({
                code: el.code,
                routingNumber: el.routingNumber,
                accountNumber: el.accountNumber,
                name: el.name,
                id: el.code,
                label: `${el.code} ${el.routingNumber !== undefined ? `- ${el.routingNumber}` : ''} ${el.accountNumber !== undefined ? `- ${el.accountNumber}` : ''} ${el.name !== undefined ? `- ${el.name}` : ''}`
            }));
            setCorpCodesOptions(CorpCodesOptions);
        });
    }, []);

    useEffect(() => {
        console.log(selectedLocations)
    }, [selectedLocations]);
    useEffect(() => {
        console.log(selectedCorpCodes)
    }, [selectedCorpCodes]);
    useEffect(() => {
        //console.log('user useEffect ', )
    }, [userCreateOrEdit]);

    const columnData = [
        { headerId: 'isAdmin', headerLabel: 'Admin', rowCellTableRowComponentsArrayIndex: 0, type: 'component' },
        { headerId: 'username', headerLabel: 'User ID' },
        { headerId: 'firstName', headerLabel: 'First' },
        { headerId: 'middleName', headerLabel: 'Middle' },
        { headerId: 'lastName', headerLabel: 'Last' },
        { headerId: 'emailAddress', headerLabel: 'Email' },
        { headerId: 'status', headerLabel: 'Status' },
        { headerId: 'lastLogin', headerLabel: 'Last login', rowCellTableRowComponentsArrayIndex: 1, type: 'component' },
        { headerId: 'action', headerLabel: 'Edit or delete', rowCellTableRowComponentsArrayIndex: 2, type: 'component', disableSorting: true }
    ];

    const handleOnChangeFirstname = (event: any) => {
        setUserCreateOrEdit({ ...userCreateOrEdit, firstName: event.target.value });
    };

    const handleOnChangeMiddlename = (event: any) => {
        setUserCreateOrEdit({ ...userCreateOrEdit, middleName: event.target.value });
    };

    const handleOnChangeLastname = (event: any) => {
        setUserCreateOrEdit({ ...userCreateOrEdit, lastName: event.target.value });
    };

    const handleOnChangeEmail = (event: any) => {
        setUserCreateOrEdit({ ...userCreateOrEdit, emailAddress: event.target.value });
    };

    const handleOnChangeUserId = (event: any) => {
        setUserCreateOrEdit({ ...userCreateOrEdit, username: event.target.value });
    };

    const handleOnChangePermission = (event: Event, permissionName: string) => {
        const newVal = (event.target as HTMLInputElement).checked;
        if (userCreateOrEdit.hasOwnProperty(permissionName)) {
            setUserCreateOrEdit({ ...userCreateOrEdit, [permissionName]: newVal });
        }
    };

    const handleSelectRowLocations = (props: any, index: number) => {
        setSelectedLocations(prvSelectedLocations => {
            if (prvSelectedLocations.includes(props.id)) {
                return prvSelectedLocations.filter(id => id !== props.id)
            } else {
                return [...prvSelectedLocations, props.id]
            }
        })
        setUserCreateOrEdit(prevUserCreateOrEdit => ({
            ...prevUserCreateOrEdit,
            lockboxCodes: selectedLocations.includes(props.id)
                ? prevUserCreateOrEdit.lockboxCodes.filter(id => id !== props.id)
                : [...prevUserCreateOrEdit.lockboxCodes, props.id]

        }));
    }

    const handleSelectAllLocations = (e: Event) => {
        setCheckedAllLocations(!checkedAllLocations);
        const checked = (e.target as HTMLInputElement).checked;
        if (checked) {
            setSelectedLocations(locationOptions.map(option => option.id));
            setUserCreateOrEdit({ ...userCreateOrEdit, lockboxCodes: locationOptions.map(option => option.id) });
        } else {
            setSelectedLocations([]);
            setUserCreateOrEdit({ ...userCreateOrEdit, lockboxCodes: [] });
        }
    }

    const handleSelectRowCorpCodes = (props: any, index: number) => {
        setSelectedCorpCodes(prvSelectedCorpCodes => {
            if (prvSelectedCorpCodes.includes(props.id)) {
                return prvSelectedCorpCodes.filter(id => id !== props.id)
            } else {
                return [...prvSelectedCorpCodes, props.id]
            }
        })
        setUserCreateOrEdit(prevUserCreateOrEdit => ({
            ...prevUserCreateOrEdit,
            corpCodes: selectedCorpCodes.includes(props.id)
                ? prevUserCreateOrEdit.corpCodes.filter(id => id !== props.id)
                : [...prevUserCreateOrEdit.corpCodes, props.id]

        }));
    }

    const handleSelectAllCorpCodes = (e: Event) => {
        setCheckedAllCorpCodes(!checkedAllCorpCodes);
        const checked = (e.target as HTMLInputElement).checked;
        if (checked) {
            setSelectedCorpCodes(corpCodesOptions.map(option => option.id));
            setUserCreateOrEdit({ ...userCreateOrEdit, corpCodes: corpCodesOptions.map(option => option.id) });
        } else {
            setSelectedCorpCodes([]);
            setUserCreateOrEdit({ ...userCreateOrEdit, corpCodes: [] });
        }
    }

    const HandleEmptyUser = () => {
        setUserCreateOrEdit({ clientCode: client_Id, username: '', isAdmin: false, isBanker: false, firstName: '', middleName: '', lastName: '', emailAddress: '', status: '', canAddUsers: false, canModifyUsers: false, canChangeUserStatuses: false, canViewUsers: false, canManageBanners: false, canManageExceptions: false, canUnlockExceptions: false, lockboxCodes: [], corpCodes: [] });
        setSelectedLocations([]);
        setCheckedAllLocations(false);
        setSelectedCorpCodes([]);
        setCheckedAllCorpCodes(false);
        setErrorMessagetitle('');
        setErrorMessagetext('');
    }
    const HandleGoBack = () => {
        HandleEmptyUser();
        handleEmptyErrorMessages();
        setManageState(UserMangeState.CurrentUsers);
    }
    const HandleRefresh = () => {
        setLoding(true);
        fetchData().then(users => {
            const updatedUsers = users.map((user: User) => ({
                ...user, rowClasses: user.isAdmin ? 'highlighted' : '',
            }))
            setUsers(updatedUsers);
            setShowSaveAddUserModal(false);
            setShowSaveEditUserModal(false);
            setModalIsOpenDelete(false);
            setLoding(false);
        });
    }
    const HandleCancelSaveUser = () => {
        HandleEmptyUser();
        handleEmptyErrorMessages();
        setManageState(UserMangeState.CurrentUsers);
    }

    const handleCSV = () => {
        generateUserEntitlementReport({ fileType: 'Csv', isAdmin: userType.isAdmin })
        setModalIsOpenDownload(true);
    }

    const setErrorSummaryFields = () => {
        const errors: ErrorMessage[] = [];

        const basicFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const hasBasicFormat = basicFormat.test(userCreateOrEdit.emailAddress.trim());
        const showErrorEmailFormat =
            (hasBasicFormat && userCreateOrEdit.emailAddress.trim().length < 6) || !hasBasicFormat;

        if (userCreateOrEdit.lastName.trim() === '') {
            errors.push({ ariaLabel: "Last name", text: "Last name", inputId: 'lastName' });
            setIsvalidLastName(false);
        } else {
            setIsvalidLastName(true);
        }

        if (userCreateOrEdit.emailAddress.trim() === "") {
            errors.push({ ariaLabel: "Email", text: "Email", inputId: 'userEmail' });
            setIsvalidEmail(false);
            setEmailMessage(["Enter a email address."]);
        } else if (showErrorEmailFormat) {
            errors.push({ ariaLabel: "Email", text: "Email", inputId: 'userEmail' });
            setEmailMessage(["Enter a valid email address."]);
            setIsvalidEmail(false);
        } else {
            setIsvalidEmail(true);
            setEmailMessage([""]);
        }

        if (userCreateOrEdit.username.trim() === '') {
            errors.push({ ariaLabel: "User ID", text: "User ID", inputId: 'userId' });
            setIsvalidUserName(false);
        } else {
            setIsvalidUserName(true);
        }

        seterrorMessagesSummary(errors);
        return errors;
    };

    const handleEmptyErrorMessages = () => {
        seterrorMessagesSummary([]);
        setEmailMessage([""]);
        setIsvalidEmail(true);
        setIsvalidUserName(true);
        setIsvalidLastName(true);
    };

    const HandleCreateNewUser = async () => {
        HandleEmptyUser();
        handleEmptyErrorMessages();
        setManageState(UserMangeState.AddUsers);
    }

    const HandleSaveCreateUser = (event: Event) => {
        event.preventDefault();
        let catchError = setErrorSummaryFields();

        if (catchError.length === 0) {
            setLoding(true);
            delete userCreateOrEdit.status;
            delete userCreateOrEdit.isBanker;
            createUser(userCreateOrEdit).then(() => {
                HandleEmptyUser();
                setShowSaveAddUserModal(true);
                setManageState(UserMangeState.CurrentUsers);
                setLoding(false);
            }).catch(error => {
                for (const propertyName in error.errors) {
                    if (error.errors.hasOwnProperty(propertyName) && propertyName === 'Username') {
                        setErrorMessagetitle('User already exists');
                        setErrorMessagetext('This user has already been added. Please try again.');
                    } else {
                        const errorProperty = Object.keys(error.errors)[0];
                        setErrorMessagetitle(error.title);
                        setErrorMessagetext(error.errors[errorProperty]);
                    }
                }
                setShowSaveErrorModal(true);
                setLoding(false);
            });
        }

    }
    const HandleEditUser = (ID: number) => {
        setLoding(true);
        setEdit_user_Id(ID);
        HandleEmptyUser();
        handleEmptyErrorMessages();
        setManageState(UserMangeState.EditUsers);
        fetchSingleUser(ID).then(user => {
            const updatedUser = { ...user, lockboxCodes: user.allowedLockboxes, corpCodes: user.allowedCorpCodes };
            setUserCreateOrEdit(updatedUser);
            setSelectedLocations(updatedUser.lockboxCodes);
            const allLocationsCheck = locationOptions.every(location => user.allowedLockboxes.includes(location.id));
            setCheckedAllLocations(allLocationsCheck);
            setSelectedCorpCodes(updatedUser.corpCodes);
            const allCorpCodesCheck = corpCodesOptions.every(corp => user.allowedCorpCodes.includes(corp.id));
            setCheckedAllCorpCodes(allCorpCodesCheck);
            setLoding(false);
        });
    }

    const HandleSaveEditUser = (event: Event) => {
        event.preventDefault();
        let catchError = setErrorSummaryFields();

        if (catchError.length === 0) {
            setLoding(true);
            const updatedUser = { ...userCreateOrEdit };
            editUser(edit_user_Id, updatedUser).then(() => {
                setErrorMessagetitle('');
                setErrorMessagetext('');
                setShowSaveEditUserModal(true);
                setManageState(UserMangeState.CurrentUsers);
                setLoding(false);
            }).catch(error => {
                for (const propertyName in error.errors) {
                    if (error.errors.hasOwnProperty(propertyName) && propertyName === 'Username') {
                        setErrorMessagetitle('User already exists');
                        setErrorMessagetext('This user has already been added. Please try again.');
                    } else {
                        const errorProperty = Object.keys(error.errors)[0];
                        setErrorMessagetitle(error.title);
                        setErrorMessagetext(error.errors[errorProperty]);
                    }
                }
                setShowSaveErrorModal(true);
                setLoding(false);
            });
        }
    }
    const HandleDeleteUser = (ID: number) => {
        setDelete_User_Id(ID);
        setModalIsOpenDelete(true);
    }
    const handleConfirmDelete = async () => {
        setLoding(true);
        await deleteUser(delete_User_Id).then(() => {
            setLoding(false);
            setModalIsOpenDelete(false);
        });
    }
    const HandleSwitchAdmin = (AdminVal: boolean | undefined) => {
        const adminVal = AdminVal
        console.log('AdminVal ', adminVal);
        if (!adminVal) {
            setUserCreateOrEdit(prevUserCreateOrEdit => ({
                ...prevUserCreateOrEdit,
                isAdmin: true, canAddUsers: true, canChangeUserStatuses: true, canManageBanners: true, canModifyUsers: true, canViewUsers: true, canManageExceptions: true, canUnlockExceptions: true, lockboxCodes: locationOptions.map(option => option.id), corpCodes: corpCodesOptions.map(option => option.id)
            }
            ));
            setSelectedLocations(locationOptions.map(option => option.id));
            setCheckedAllLocations(true);
            setSelectedCorpCodes(corpCodesOptions.map(option => option.id));
            setCheckedAllCorpCodes(true);
        } else {
            setUserCreateOrEdit(prevUserCreateOrEdit => ({
                ...prevUserCreateOrEdit,
                isAdmin: false, canAddUsers: false, canChangeUserStatuses: false, canManageBanners: false, canModifyUsers: false, canViewUsers: false, canManageExceptions: false, canUnlockExceptions: false, lockboxCodes: [], corpCodes: []
            }));
            setSelectedLocations([]);
            setCheckedAllLocations(false);
            setSelectedCorpCodes([]);
            setCheckedAllCorpCodes(false);
        }
    }
    const HandleSwitchActive = (Status: string | undefined) => {
        if (Status === "Active") {
            setUserCreateOrEdit({ ...userCreateOrEdit, status: 'Suspended' });
        } else {
            setUserCreateOrEdit({ ...userCreateOrEdit, status: 'Active' });
        }
    }

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            <h4 ref={initialTextRef} tabIndex={-1} className='heading-title heading-focus-text'>User management<span className='pipe'>|</span>{manageState === UserMangeState.CurrentUsers ? 'Current users' : manageState === UserMangeState.EditUsers ? 'Edit user' : 'Add a new user'}</h4>
            <div className={manageState === UserMangeState.CurrentUsers ? 'mange-buttons-container' : 'mange-buttons-container-actions'}>
                {(manageState === UserMangeState.CurrentUsers && userType.isAdmin) ?
                    <Button
                        classes=""
                        clickMethod={() => { HandleCreateNewUser() }}
                        text="Create new user"
                        variant="secondary"
                        size="medium"
                    />
                    : (manageState === UserMangeState.EditUsers || manageState === UserMangeState.AddUsers) ?
                        <Button
                            classes="go-back"
                            clickMethod={() => { HandleGoBack() }}
                            text="Go back to current users"
                            variant="text-primary"
                            icon='IconChevronLeft'
                            iconPosition='left'
                        />
                        :
                        <>
                        </>
                }
            </div>
            <div className='download-report-container'>
                {(manageState === UserMangeState.CurrentUsers && userType.isAdmin) ?
                    <CustomModal
                        downloadIconButton={true}
                        showClose={true}
                        ButtonTitle='Download report as CSV'
                        ModalIsOpen={modalIsOpenDownload}
                        OpenModal={handleCSV}
                        CloseModal={() => { setModalIsOpenDownload(false) }}
                        ModalText='Your report has been requested and will be available in Reports.'
                        ModalTitle='Request submitted'
                    />
                    :
                    <>
                    </>
                }
            </div>


            {manageState === UserMangeState.CurrentUsers ?
                <div className='loader-section'>
                    <Loader isLoading={loading} />
                    {users && users.length > 0 ?
                        <div>
                            <Table
                                caption="Users"
                                columnData={userType.isAdmin ? columnData : columnData.filter(col => col.headerId !== 'action').map(el => el)}
                                data={!userType.isBanker ? users.filter(el => el.status !== "Deleted").map(el => el) : users}
                                isLined
                                isSortable
                                tableRowComponents={[
                                    (props: any) => (
                                        <div className='medical-items'>
                                            {(props.row.original.isAdmin === true) ?
                                                <span>Yes</span>
                                                :
                                                <span>No</span>
                                            }

                                        </div>
                                    ),
                                    (props: any) => (
                                        <DateDisplay date={props.row.original.lastLogin} format="paddedShortDateTime" gmt />
                                    ),
                                    (props: any) => (
                                        <div>
                                            {props.row.original.status === "Deleted" ?
                                                <></>
                                                :
                                                <div className='report-actions'>
                                                    <span className='action-icon'><IconEditPencil style={{ color: '#007C8C' }} width="20" height="20" /></span>
                                                    <Button
                                                        clickMethod={() => { HandleEditUser(props.row.original.id) }}
                                                        text="Edit"
                                                        variant="text-primary"
                                                    /><span className='action-separator'> | </span>
                                                    <span className='action-icon'><IconTrash style={{ color: '#007C8C' }} width="18" height="18" /></span>
                                                    <Button
                                                        clickMethod={() => { HandleDeleteUser(props.row.original.id) }}
                                                        text="Delete"
                                                        variant="text-primary"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ),
                                ]}
                            />
                            <ModalsContainer noGap>
                                <CustomModal yesOrNoType ModalIsOpen={modalIsOpenDelete}
                                    CloseModal={() => { setModalIsOpenDelete(false) }}
                                    noText='No, I do not want to delete it' yesText='Yes, I want to delete it'
                                    yesClickMethod={handleConfirmDelete}
                                    ModalText='Are you sure you want to delete this user? The information will no longer be available.'
                                    ModalTitle='Deleting user' />
                                <CustomModal
                                    okRefreshType
                                    Refresh={() => { HandleRefresh(); }}
                                    ModalText="Your new user has been added. You can edit or delete the information in Current users."
                                    ModalTitle="User added"
                                    ModalIsOpen={showSaveAddUserModal}
                                    CloseModal={() => {
                                        setShowSaveAddUserModal(false);
                                    }}
                                />
                                <CustomModal
                                    okRefreshType
                                    Refresh={() => { HandleRefresh(); }}
                                    ModalText="Your user has been updated. You can edit or delete the information in Current users."
                                    ModalTitle="User updated"
                                    ModalIsOpen={showSaveEditUserModal}
                                    CloseModal={() => {
                                        setShowSaveEditUserModal(false);
                                    }}
                                />
                            </ModalsContainer>
                        </div>
                        :
                        <p className='zero-state'>You don’t have any users yet.</p>
                    }
                </div>
                :
                <div className='loader-section'>
                    <Loader isLoading={loading} />
                    <ModalsContainer noGap>
                        <CustomModal okRefreshType Refresh={() => {
                            setShowSaveErrorModal(false);
                            setErrorMessagetitle('');
                            setErrorMessagetext('');
                        }}
                            ModalText={errorMessagetext}
                            ModalTitle={errorMessagetitle} ModalIsOpen={showSaveErrorModal} CloseModal={() => {
                                setShowSaveErrorModal(false); setErrorMessagetitle('');
                                setErrorMessagetext('');
                            }} />
                    </ModalsContainer>
                    <div className='create-edit-fields'>
                        <p className='section-title'>User information</p>
                        {errorMessagesSummary.length !== 0 &&
                            <ErrorSummary
                                errors={errorMessagesSummary}
                                ref={errorSummaryRef}
                            />
                        }
                        <div className='input-box-container'>
                            <div className='input-box__medium'>
                                <TextInput
                                    label="First name"
                                    initialValue={userCreateOrEdit.firstName}
                                    size='small'
                                    handleOnChange={handleOnChangeFirstname}
                                    type="text"
                                />
                            </div>
                            <div className='input-box__small left-24-gap-filters'>
                                <TextInput
                                    label="Middle initial"
                                    initialValue={userCreateOrEdit.middleName}
                                    size='small'
                                    handleOnChange={handleOnChangeMiddlename}
                                    type="text"
                                />
                            </div>
                            <div className='input-box__large left-24-gap-filters'>
                                <TextInput
                                    label="Last name"
                                    initialValue={userCreateOrEdit.lastName}
                                    size='small'
                                    handleOnChange={handleOnChangeLastname}
                                    type="text"
                                    errorMessages={["Enter a last name."]}
                                    isValid={isvalidLastName}
                                    inputId="lastName"
                                />
                            </div>
                        </div>
                        <div className='input-box-container'>
                            <div className='input-box__xlarge'>
                                <EmailInput
                                    label="Email"
                                    initialValue={userCreateOrEdit.emailAddress}
                                    size='small'
                                    handleOnChange={handleOnChangeEmail}
                                    type="text"
                                    isValid={isvalidEmail}
                                    inputId="userEmail"
                                    errorMessages={emailMessage}
                                />
                            </div>
                            <div className='input-box__medium left-24-gap-filters'>
                                <TextInput
                                    label="User ID"
                                    initialValue={userCreateOrEdit.username}
                                    size='small'
                                    handleOnChange={handleOnChangeUserId}
                                    type="text"
                                    errorMessages={["Enter a user ID."]}
                                    isValid={isvalidUserName}
                                    inputId="userId"
                                />
                            </div>
                        </div>
                        <div className={userType.isBanker ? 'switch-box-container-banker' : 'switch-box-container-client'}>
                            {(manageState === UserMangeState.EditUsers && userType.isBanker && userType.isAdmin) &&
                                <div className='switch-box rigth-98-gap'>
                                    <span id="switch-labelled-by-small">{userCreateOrEdit.clientCode === "REGIONS" ? 'Is this associate a Regions LockboxIQ administrator?' : 'Is this person a client administrator?'}</span>
                                    <div className='left-16-gap top-4-gap'><Switch isChecked={userCreateOrEdit.isAdmin} handleOnChange={() => { HandleSwitchAdmin(userCreateOrEdit.isAdmin) }} size="small" ariaLabelledBy="switch-status" /></div>
                                    {userCreateOrEdit.isAdmin ? <span className='left-16-gap'>Yes</span> : <span className='left-16-gap'>No</span>}
                                </div>
                            }
                            {(manageState === UserMangeState.AddUsers && userType.isBanker && userType.isAdmin) &&
                                <div className='switch-box rigth-98-gap'>
                                    <span id="switch-labelled-by-small">Is this person an administrator?</span>
                                    <div className='left-16-gap top-4-gap'><Switch isChecked={userCreateOrEdit.isAdmin} handleOnChange={() => { HandleSwitchAdmin(userCreateOrEdit.isAdmin) }} size="small" ariaLabelledBy="switch-admin" /></div>
                                    {userCreateOrEdit.isAdmin ? <span className='left-16-gap'>Yes</span> : <span className='left-16-gap'>No</span>}
                                </div>
                            }
                            {(userType.isAdmin && !userType.isBanker) &&
                                <div className='switch-box rigth-98-gap'>
                                    <span id="switch-labelled-by-small">Is this person an administrator?</span>
                                    <div className='left-16-gap top-4-gap'><Switch isChecked={userCreateOrEdit.isAdmin} handleOnChange={() => { HandleSwitchAdmin(userCreateOrEdit.isAdmin) }} size="small" ariaLabelledBy="switch-admin" /></div>
                                    {userCreateOrEdit.isAdmin ? <span className='left-16-gap'>Yes</span> : <span className='left-16-gap'>No</span>}
                                </div>
                            }
                            {(manageState === UserMangeState.EditUsers && userType.isAdmin) &&
                                <div className='switch-box'>
                                    <span id="switch-labelled-by-small">User account status:</span>
                                    <div className='left-16-gap top-4-gap'><Switch isChecked={userCreateOrEdit.status === "Active" ? true : false} handleOnChange={() => { HandleSwitchActive(userCreateOrEdit.status) }} size="small" ariaLabelledBy="switch-status" /></div>
                                    {userCreateOrEdit.status === "Active" ? <span className='left-16-gap'>Active</span> : <span className='left-16-gap'>Suspended</span>}
                                </div>
                            }
                        </div>
                        <div className='card-divider'></div>
                        <div className='checkbox-group'>
                            <p className='table-title'>User permissions</p>
                            <Checkbox
                                isChecked={userCreateOrEdit.canAddUsers}
                                handleOnChange={(e: Event) => handleOnChangePermission(e, 'canAddUsers')}
                                label="Add users"
                                aria-labelledby="Select"
                            />
                            <Checkbox
                                isChecked={userCreateOrEdit.canChangeUserStatuses}
                                handleOnChange={(e: Event) => handleOnChangePermission(e, 'canChangeUserStatuses')}
                                label="Change user status"
                                aria-labelledby="Select"
                            />
                            <Checkbox
                                isChecked={userCreateOrEdit.canManageBanners}
                                handleOnChange={(e: Event) => handleOnChangePermission(e, 'canManageBanners')}
                                label="Modify banner"
                                aria-labelledby="Select"
                            />
                            <Checkbox
                                isChecked={userCreateOrEdit.canModifyUsers}
                                handleOnChange={(e: Event) => handleOnChangePermission(e, 'canModifyUsers')}
                                label="Modify users"
                                aria-labelledby="Select"
                            />
                            <Checkbox
                                isChecked={userCreateOrEdit.canViewUsers}
                                handleOnChange={(e: Event) => handleOnChangePermission(e, 'canViewUsers')}
                                label="View users"
                                aria-labelledby="Select"
                            />
                            <Checkbox
                                isChecked={userCreateOrEdit.canManageExceptions}
                                handleOnChange={(e: Event) => handleOnChangePermission(e, 'canManageExceptions')}
                                label="Manage exceptions"
                                aria-labelledby="Select"
                            />
                            <Checkbox
                                isChecked={userCreateOrEdit.canUnlockExceptions}
                                handleOnChange={(e: Event) => handleOnChangePermission(e, 'canUnlockExceptions')}
                                label="Unlock exceptions"
                                aria-labelledby="Select"
                            />
                        </div>
                        <div className='card-divider'></div>
                        <div className='user-management-table'>
                            <div className=' bottom-gap-10'>
                                <p className="table-title table-title--info">Allowed Lockboxes</p>
                            </div>
                            <Table
                                caption="Locations"
                                columnData={[
                                    { headerId: 'code', headerLabel: 'Lockbox', rowCellTableRowComponentsArrayIndex: 2,  type: 'component', },
                                    { headerId: 'select', headerLabel: 'Select', rowCellTableRowComponentsArrayIndex: 0, headerCellTableRowComponentsArrayIndex: 1, type: 'component', disableSorting: true },
                                ]}
                                data={locationOptions}
                                showSupplementalStatus
                                supplementalStatus={`${selectedLocations.length} of ${locationOptions.length} ${locationOptions.length > 1 ? 'rows' : 'row'} selected for download`}
                                isLined
                                isSortable
                                tableRowComponents={[
                                    (props: any) => (
                                        <div className='center-select'>
                                            <Checkbox
                                                isChecked={selectedLocations.includes(props.row.original.id)}
                                                inputId={props.row.original.headerId}
                                                handleOnChange={() => handleSelectRowLocations(props.row.original, props.row.index)}
                                                label=""
                                                aria-labelledby="Select"
                                            />
                                        </div>
                                    ),
                                    (props: any) => (
                                        <div className='table-select-all'>
                                            <span>Allow Lockbox access (select all)</span>
                                            <Checkbox
                                                isChecked={checkedAllLocations}
                                                handleOnChange={(e: Event) => handleSelectAllLocations(e)}
                                                label=""
                                                aria-labelledby="Select all"
                                            />
                                        </div>
                                    ),
                                    (props: any) => (
                                        <div className='select-code-column'>
                                            <span>{props.row.original.label}</span>
                                        </div>
                                    ),
                                ]}
                            />
                        </div>
                        {corpCodesOptions!.length > 0 && (
                            <div className='user-management-table top-30-gap'>
                                <div className=' bottom-gap-10'>
                                    <p className="table-title table-title--info">Allowed corp codes</p>
                                </div>
                                <Table
                                    caption="Corp codes"
                                    columnData={[
                                        { headerId: 'code', headerLabel: 'Corp Code', rowCellTableRowComponentsArrayIndex: 2, type: 'component', },
                                        { headerId: 'select', headerLabel: 'Select', rowCellTableRowComponentsArrayIndex: 0, headerCellTableRowComponentsArrayIndex: 1, type: 'component', disableSorting: true },
                                    ]}
                                    data={corpCodesOptions}
                                    showSupplementalStatus
                                    supplementalStatus={`${selectedCorpCodes.length} of ${corpCodesOptions.length} ${corpCodesOptions.length > 1 ? 'rows' : 'row'} selected for download`}
                                    isLined
                                    isSortable
                                    tableRowComponents={[
                                        (props: any) => (
                                            <div className='center-select'>
                                                <Checkbox
                                                    isChecked={selectedCorpCodes.includes(props.row.original.id)}
                                                    inputId={props.row.original.headerId}
                                                    handleOnChange={() => handleSelectRowCorpCodes(props.row.original, props.row.index)}
                                                    label=""
                                                    aria-labelledby="Select"
                                                />
                                            </div>
                                        ),
                                        (props: any) => (
                                            <div className='table-select-all'>
                                                <span>Allow Corp Code access (select all)</span>
                                                <Checkbox
                                                    isChecked={checkedAllCorpCodes}
                                                    handleOnChange={(e: Event) => handleSelectAllCorpCodes(e)}
                                                    label=""
                                                    aria-labelledby="Select all"
                                                />
                                            </div>
                                        ),
                                        (props: any) => (
                                            <div className='select-code-column'>
                                                <span>{props.row.original.label}</span>
                                            </div>
                                        )
                                    ]}
                                />
                            </div>)
                        }
                    </div>
                    <div className='card-divider'></div>
                    <ButtonContainer noTopGap>
                        {manageState === UserMangeState.AddUsers ?
                            <Button
                                clickMethod={(e: Event) => { HandleSaveCreateUser(e) }}
                                size="medium"
                                text="Save user"
                            />
                            : manageState === UserMangeState.EditUsers ?
                                <Button
                                    clickMethod={(e: Event) => { HandleSaveEditUser(e) }}
                                    size="medium"
                                    text="Save user"
                                />
                                :
                                <></>
                        }
                        <Button
                            clickMethod={() => { HandleCancelSaveUser() }}
                            size="medium"
                            text="Cancel"
                            variant="secondary"
                            classes="left-24-gap-filters"
                        />
                    </ButtonContainer>
                </div>

            }

        </main>
    );
};

export default UserManagement;