/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, FormEvent, useRef } from 'react';
import dateformat from "dateformat";

import BatchMirror from '../components/BatchMirror';
import FilterForm from '../components/FilterForm';
import ModalsContainer from '../components/ModalsContainer';
import BatchdataSummary from '../API_Integrations/BatchSummary';
import CustomModal from '../components/CustomModal';
import Table from '../components/Table';
import { IBatchSummary_req_body } from '../interfaces/IBatchSummary';
import { IDownloadObject } from '../interfaces/IBatchSummary';
import { useTitle } from '../utils/use-title';
import { useDifferenceDate, ifStartNull, ifEndNull } from '../utils/use-date-calculator';

import { BatchSummaryData, batch_summary_reqBody_startDate, batch_summary_reqBody_endDate, batch_summary_reqBody_payment, batch_summary_reqBody_pageNumber, batch_summary_reqBody_pageSize, batch_summary_reqBody_lockbox, BatchSummaryMirror, batch_number_detail, batch_ID, banker_summary_reqBody_startDate, banker_summary_reqBody_endDate, userState } from '../atom';
import { useRecoilState, useRecoilValue } from 'recoil';

// @ts-ignore
import { Checkbox, CheckboxGroup, DropdownMenu, DropdownTrigger, DropdownMenuContent, RadioGroup, Button, ErrorSummary } from '@ux/balance-react';
import { useNavigate, useParams } from 'react-router-dom-v6';
import ClientHeader from '../components/ClientHeader';
import DateDisplay from '../components/DateDisplay';
import Loader from '../components/Loader';
import { useRepeatAnnouncement } from '../utils/use-repeat-live-announcement';

interface ErrorMessage { ariaLabel: string, text: string, inputId: string }
interface TableCaptionDates {start: string | null, end: string | null}

function BatchSummary() {
    const { lockboxCode } = useParams();
    const [loading, setLoding] = useState(true);
    //const loading = useRecoilValue(BreadcrumbLoader);
    const lockbox = useRecoilValue(batch_summary_reqBody_lockbox);
    const bankerSummaryStartDate = useRecoilValue(banker_summary_reqBody_startDate);
    const bankerSummaryEndDate = useRecoilValue(banker_summary_reqBody_endDate);
    const [data_summary, setData_summary] = useRecoilState(BatchSummaryData);
    //Batch summary mirror
    const batchMirror = useRecoilValue(BatchSummaryMirror);
    useTitle("Batch summary");

    //Batch Detail
    const [batch, setBatch] = useRecoilState(batch_number_detail);
    const [batchId, setBatchId] = useRecoilState(batch_ID);

    const [modalIsOpenZip, setModalIsOpenZip] = useState(false);
    const [modalIsOpenPDF, setModalIsOpenPDF] = useState(false);
    //
    const [startDate, setStartDate] = useRecoilState(batch_summary_reqBody_startDate);
    const [endDate, setEndDate] = useRecoilState(batch_summary_reqBody_endDate);
    const [selectValue, setSelectValue] = useRecoilState(batch_summary_reqBody_payment);
    const [showValidationDateDiff, setShowValidationDateDiff] = useState(false);
    const [tableCaptionDates, setTableCaptionDates] = useState<TableCaptionDates>({start: '', end: ''})

    //checkbox    
    const [checkedAll, setCheckedAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState(Array((data_summary?.items || []).length).fill(false));
    const [downloadObject, setDownloadObject] = useState<IDownloadObject>({ documents: false, checks: false, fileType: '' });
    const [transactionNotSelected, setTransactionNotSelected] = useState(false);
    const [errorMessagesSummary, seterrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [isvalidDocument, setIsvalidDocument] = useState(true);
    const [isvalidFile, setIsvalidFile] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [liveAnnouncementText, setLiveAnnouncementText] = useState("");
    const errorSummaryRef = useRef<HTMLElement | null>(null);
    const navigate = useNavigate();
    //skip checkbox
    const [skipRows, setSkipRows] = useState<boolean[]>(Array((data_summary?.items || []).length).fill(false));
    const [skipLoading, setSkipLoading] = useState(false);

    const [user, setUser] = useRecoilState(userState);


    useEffect(() => {
        if (bankerSummaryStartDate === new Date() && bankerSummaryEndDate === new Date()) {
            setStartDate(new Date());
            setEndDate(new Date());
            setSelectValue('');
            getBatchSummary({
                "lockboxCode": lockboxCode ?? "",
                "filters": '',
                "sorts": "-depositDate,batchNumber",
                "startDate": dateformat(new Date(), "yyyy-mm-dd"),
                "endDate": dateformat(new Date(), "yyyy-mm-dd")
            }).then(()=> {
                setTableCaptionDates({start: dateformat(new Date(), "mm/dd/yyyy"), end: dateformat(new Date(), "mm/dd/yyyy")});
            });

        } else {
            setStartDate(bankerSummaryStartDate);
            setEndDate(bankerSummaryEndDate);
            setSelectValue('');
            let start_date = bankerSummaryStartDate;
            let end_date = bankerSummaryEndDate;
            const formattedStartDate = start_date !== null ? dateformat(bankerSummaryStartDate, "yyyy-mm-dd") : dateformat(ifStartNull(bankerSummaryEndDate) , "yyyy-mm-dd");
            const formattedEndDate = end_date !== null ? dateformat(bankerSummaryEndDate, "yyyy-mm-dd") : dateformat(ifEndNull(bankerSummaryStartDate) , "yyyy-mm-dd");
            getBatchSummary({
                "lockboxCode": lockboxCode ?? "",
                "filters": '',
                "sorts": "-depositDate,batchNumber",
                "startDate": formattedStartDate,
                "endDate": formattedEndDate
            }).then(() => {
                const formattedCaptionDateStart = start_date !== null ? dateformat(ifStartNull(start_date), "mm/dd/yyyy") : dateformat(ifStartNull(end_date), "mm/dd/yyyy");
                const formattedCaptionDateEnd = end_date !== null ? dateformat(end_date, "mm/dd/yyyy") : dateformat(ifEndNull(start_date), "mm/dd/yyyy");
                setTableCaptionDates({start: formattedCaptionDateStart, end: formattedCaptionDateEnd});
            });
        }
        seterrorMessagesSummary([]);
    }, [lockbox]);

    useEffect(() => {
    }, [data_summary]);

    useEffect(() => {
    }, [lockbox]);

    //start date
    const HandleOnChangeStartDate = (date: any) => {
        setStartDate(date);
    }
    //end date
    const HandleOnChangeEndDate = (date: any) => {
        setEndDate(date);
    }

    useEffect(() => {
    }, [startDate]);

    useEffect(() => {
    }, [endDate]);

    useEffect(() => {
    }, [selectValue]);

    //filter
    let announcement  = "Loading, please wait.";
    const repeatLoadingAnnouncement = () => {
        setLiveAnnouncementText(announcement);
        announcement += '.';
    };

    useRepeatAnnouncement({repeatLoadingAnnouncement, loadingSearch});

    const HandleFilter = async () => {
        let start_date = startDate;
        let end_date = endDate;
        const formattedStartDate = start_date !== null ? dateformat(start_date, "yyyy-mm-dd") : dateformat(ifStartNull(end_date), "yyyy-mm-dd");
        const formattedEndDate = end_date !== null ? dateformat(end_date, "yyyy-mm-dd") : dateformat(ifEndNull(start_date), "yyyy-mm-dd");
        const isNotvalid: boolean = useDifferenceDate(startDate, endDate);
        if (!isNotvalid) {
            setLoadingSearch(true);
            setLiveAnnouncementText("Loading, please wait.");
            try {
                const result = await BatchdataSummary.details({
                    "lockboxCode": lockboxCode ?? "",
                    "filters": '',
                    "sorts": "-depositDate,batchNumber",
                    "startDate": formattedStartDate,
                    "endDate": formattedEndDate
                });
                if (result !== undefined) {
                    setData_summary(result);
                    setSelectedRows(Array(result.items.length).fill(false));
                    const formattedCaptionDateStart = start_date !== null ? dateformat(start_date, "mm/dd/yyyy") : dateformat(ifStartNull(end_date), "mm/dd/yyyy");
                    const formattedCaptionDateEnd = end_date !== null ? dateformat(end_date, "mm/dd/yyyy") : dateformat(ifEndNull(start_date), "mm/dd/yyyy");
                    setTableCaptionDates({ start: formattedCaptionDateStart, end: formattedCaptionDateEnd });
                    if (result && result.items.length > 1) {
                        setLiveAnnouncementText(`${result.items.length} results found.`);
                    } else if (result && result.items.length === 1) {
                        setLiveAnnouncementText(`${result.items.length} result found.`);
                    } else {
                        setLiveAnnouncementText("No result found. Please set filters and try again.");
                    }
                }

            } catch (error) {
                console.log(error);
            } finally {
                setLoadingSearch(false);
            }
        } else {
            setShowValidationDateDiff(true);
        }
    }

    const handleBatchDetailSelection = (row: any) => {
        setLoding(true);
        setBatchId(row.batchId);
        setBatch(row.batchNumber);
        navigate(`/batch-detail/${row.batchId}`);
    };

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();

                const targetedElement = document.activeElement as HTMLElement;
                const searchForm = document.getElementById('batch-summary-form') as HTMLFormElement | null;
                if (searchForm && searchForm.checkValidity() && (targetedElement.classList.contains(' batch-summary-search-button') || targetedElement.tagName === 'BODY')) {
                    HandleFilter();
                }
                if(targetedElement.tagName === 'BUTTON' && !targetedElement.classList.contains(' batch-summary-search-button')) {
                    targetedElement.click();
                }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [HandleFilter]);

    //batch summary API
    const getBatchSummary = async (ReqBody: IBatchSummary_req_body) => {
        setLoding(true);
        let result = await BatchdataSummary.details(ReqBody)
        //console.log('res from summary: ', result);
        if (result !== undefined) {
            setData_summary(result);
            setSelectedRows(Array(result.items.length).fill(false));
            setLoding(false);
        } else {
            setLoding(false);
            console.log('items not found');
        }
    }

    //toPDF
    const handlePDF = () => {
        let selectedBatches = data_summary.items
            .filter((_, index) => selectedRows[index])
            .map(value => value.batchId);

        BatchdataSummary.generateReport({
            lockboxCode: lockboxCode ?? "",
            startDate: dateformat(startDate, "yyyy-mm-dd"),
            endDate: dateformat(endDate, "yyyy-mm-dd"),
            filters: selectValue,
            sorts: "-depositDate,batchNumber",
            selectedBatches: selectedBatches,
            includeChecks: downloadObject.checks,
            includeDocuments: downloadObject.documents,
            fileType: 'pdf'
        })

        setModalIsOpenPDF(true);
    }

    //to zip
    const handleZip = () => {
        let selectedBatches = data_summary.items
            .filter((_, index) => selectedRows[index])
            .map(value => value.batchId);

        BatchdataSummary.generateReport({
            lockboxCode: lockboxCode ?? "",
            startDate: dateformat(startDate, "yyyy-mm-dd"),
            endDate: dateformat(endDate, "yyyy-mm-dd"),
            filters: selectValue,
            sorts: "-depositDate,batchNumber",
            selectedBatches: selectedBatches,
            includeChecks: downloadObject.checks,
            includeDocuments: downloadObject.documents,
            fileType: 'zip'
        })

        setModalIsOpenZip(true);
    }

    const handleSelectRow = (index: number) => {
        var selRows = [...selectedRows];
        selRows[index] = !selRows[index];
        setSelectedRows(selRows);
        if (selRows.some((element: boolean) => !element)) {
            setCheckedAll(false);
        }
        else {
            setCheckedAll(true);
        }
    }

    const handleSelectAll = () => {
        console.log(selectedRows)
        setCheckedAll(!checkedAll);
        if (checkedAll) {
            var setAllRowsFalse = selectedRows.map((function () { return !1 }));
            setSelectedRows(setAllRowsFalse);
        }
        else {
            var setAllRowsTrue = selectedRows.map((function () { return !0 }));
            setSelectedRows(setAllRowsTrue);
        }
    }

    const handleOnChangeFileType = (fileVal: string) => {
        setDownloadObject({ ...downloadObject, fileType: fileVal });
    }

    const handleOnChangeDocuments = (event: Event, documentName: string) => {
        const newVal = (event.target as HTMLInputElement).checked;
        if (downloadObject.hasOwnProperty(documentName)) {
            setDownloadObject({ ...downloadObject, [documentName]: newVal });
        }
    };
    const validateForm = () => {
        const errors: ErrorMessage[] = [];

        if (!downloadObject.checks && !downloadObject.documents) {
            errors.push({ ariaLabel: "Document type is required", text: "Choose document type", inputId: 'documentSelection-fieldset' });
            setIsvalidDocument(false);
        } else {
            setIsvalidDocument(true);
        }

        if (downloadObject.fileType === "") {
            errors.push({ ariaLabel: "File type is required", text: "Choose file type", inputId: 'fileSelect-fieldset' });
            setIsvalidFile(false);
        } else {
            setIsvalidFile(true);
        }
       
        seterrorMessagesSummary(errors);
        return errors;
    }
    const handleDownloadTransactions = () => {
        let selectedBatches = data_summary.items
            .filter((_, index) => selectedRows[index])
            .map(value => value.batchId);
        if (selectedBatches.length > 0) {
            let catchError = validateForm();

            if (catchError.length === 0) {
                console.log('downloadObject ', downloadObject);
                if (downloadObject.fileType === "PDF") {
                    handlePDF();
                } else if ((downloadObject.fileType === "Zip")) {
                    handleZip();
                }
            }
        } else {
            setTransactionNotSelected(true);
        }
    }

    const updateSkipFlag = async (batchId: number, skip: boolean) => {
        console.log("updateSkipFlag() called")
        setSkipLoading(true);
        try {
            // // Make API request to update is_skipped flag SKIP ALL ITEMS IN THIS TRANSACTION BATCH
            // await fetch(`/management-api/batch-items/${batchId}/skip`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({ is_skipped: skip })
            // });
    
            // // After successful update, re-fetch batch summary data to rerender the table
            // await getBatchSummary({
            //     "lockboxCode": lockboxCode ?? "",
            //     "filters": '',
            //     "sorts": "-depositDate,batchNumber",
            //     "startDate": dateformat(startDate, "yyyy-mm-dd"),
            //     "endDate": dateformat(endDate, "yyyy-mm-dd")
            // });
        } catch (error) {
            console.error("Error updating skip flag: ", error);
        } finally {
            setSkipLoading(false);
        }
    };

    const handleSelectSkipRow = async (index: number) => {
        console.log("hanldeSelectSkipRow() called")
        const batch = data_summary.items[index];  // Get the specific batch being updated
        const updatedSkipStatus = !skipRows[index];  // Toggle the skip status for this batch

        // Update local state to reflect the new skip status
        setSkipRows(prev => {
            const newSkipRows = [...prev];
            newSkipRows[index] = updatedSkipStatus;
            console.log("Updated SkipRows: ", newSkipRows)
            return newSkipRows;
        });

        // Make API call to update the `is_skipped` flag in the backend
        await updateSkipFlag(batch.batchId, updatedSkipStatus);
};

const handleSkipBatch = (index: number) => {
    const newSkipRows = [...skipRows];
    newSkipRows[index] = !newSkipRows[index];
    setSkipRows(newSkipRows);
};

const handleSkipAllBatches = async () => {
    setSkipLoading(true);
    try {
        const selectedBatchIds = data_summary.items
            .filter((_, index) => skipRows[index])
            .map(batch => batch.batchId);

            if (selectedBatchIds.length > 0) {
                for (const batchId of selectedBatchIds) {
                    await BatchdataSummary.skipAllTransactionsInBatch(batchId);
                }
                console.log('Skipped selected batches successfully.');
                // refresh the table after skipping
                await getBatchSummary({
                    lockboxCode: lockboxCode ?? "",
                    filters: '',
                    sorts: "-depositDate,batchNumber",
                    startDate: dateformat(startDate, "yyyy-mm-dd"),
                    endDate: dateformat(endDate, "yyyy-mm-dd")
                });

                // Reset selected rows and skip rows to ensure checkboxes are cleared
                setSelectedRows(Array(data_summary.items.length).fill(false));
                setSkipRows(Array(data_summary.items.length).fill(false));
            }
    } catch (error) {
        console.error("Error skipping selected batches: ", error);
    } finally {
        setSkipLoading(false);
    }
};

const columns = [
    {
      headerId: 'select',
      headerLabel: 'Select all',
      type: 'component',
      rowCellTableRowComponentsArrayIndex: 0,
      headerCellTableRowComponentsArrayIndex: 1,
      disableSorting: true
    },
    {
      headerId: 'depositDate',
      headerLabel: 'Deposit date',
      type: 'component',
      rowCellTableRowComponentsArrayIndex: 3
    },
    {
      headerId: 'batchNumber',
      headerLabel: 'Batch number',
      type: 'component',
      rowCellTableRowComponentsArrayIndex: 2
    },
    {
      headerId: 'transactionsFormatted',
      headerLabel: 'Transactions'
    },
    {
      headerId: 'documentsFormatted',
      headerLabel: 'Documents'
    },
    {
      headerId: 'batchTotalFormatted',
      headerLabel: 'Batch total'
    },
    {
      headerId: 'paymentMethod',
      headerLabel: 'Payment method'
    },
    {
      headerId: 'status',
      headerLabel: 'Batch status'
    }
];

if (user?.isBanker) {
    columns.push({
        headerId: 'skip',
        headerLabel: 'Skip',
        type: 'component',
        rowCellTableRowComponentsArrayIndex: 4
    });
}

// Row components
const rowComponents = [
    (props: any) => (
        <div className='center-select'>
            <Checkbox
                isChecked={selectedRows[props.row.index]}
                inputId={`input-${props.row.original.batchId}`}
                handleOnChange={() => handleSelectRow(props.row.index)}
                label={`Select ${props.row.original.depositDateFormatted} batch ${props.row.original.batchNumber}`}
                labelIsHidden
            />
        </div>
    ),
    (props: any) => (
        <div className='table-select-all'>
            <span>Select all</span>
            <Checkbox
                isChecked={checkedAll}
                handleOnChange={() => handleSelectAll()}
                label="Select all batches"
                labelIsHidden
                inputId="selectAllBatches"
            />
        </div>
    ),
    (props: any) => (
        <div className='center-link'>
            <Button
                variant="text-primary"
                clickMethod={() => handleBatchDetailSelection(props.row.original)}
                text={props.row.original.batchNumber}
                classes="handle-batch-detail-selection"
            />
        </div>
    ),
    (props: any) => (
        <DateDisplay date={props.row.original.depositDate} format="paddedShortDate" gmt />
    )
];

if (user?.isBanker) {
    rowComponents.push((props: any) => (
        <div className='center-checkbox'>
            <Checkbox
                isChecked={skipRows[props.row.index]}  // Local skip state
                inputId={`skip-${props.row.original.batchId}`}
                handleOnChange={() => handleSkipBatch(props.row.index)}  // Toggle local state for skip
                label={`Skip batch ${props.row.original.batchNumber}`}
                labelIsHidden
            />
        </div>
    ));
}

    

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={true} />
        

            {!loading ?
            <>
            <BatchMirror
                lockbox_name={data_summary.lockboxName}
                lockbox_code={data_summary.lockboxCode}
                transaction={data_summary.transactionCountFormatted}
                boxNumber={data_summary.lockboxCode}
                batchCount={data_summary.batchCountFormatted}
                total={data_summary.depositAmountFormatted}
                status={data_summary.status}
                site={data_summary.siteName} />
            <FilterForm
                startDate={startDate}
                handleOnChangeStartDate={HandleOnChangeStartDate}
                endDate={endDate}
                handleOnChangeEndDate={HandleOnChangeEndDate}
                handleFilter={(e: FormEvent<HTMLFormElement>) => { e.preventDefault(); HandleFilter(); }}
                isLoading={loadingSearch}
            >
                {data_summary && data_summary?.items?.length > 0 ?
                <DropdownMenu>
                {user?.isBanker && (
                <Button
                    classes="skip-batches-button"
                    size="medium"
                    text="Skip Batches"
                    clickMethod={() => handleSkipAllBatches()}
                    style={{ marginRight: '5px' }} 
                    disabled={skipLoading || skipRows.every(row => !row)}
                />
                )}
                    <DropdownTrigger
                        text="Download transactions"
                        ariaLabel="Download transactions menu"
                        dataTestId="download-transactions-menu"
                        variant="alternate"
                        size="medium"
                        classes="highlighted-active"
                    />
                    <DropdownMenuContent>
                        
                        <p className='title'>Choose download options</p>
                            <div className='dropdown-divider'></div>
                            {errorMessagesSummary.length !== 0 &&
                            <ErrorSummary
                                errors={errorMessagesSummary}
                                ref={errorSummaryRef}
                            />
                        }
                        <CheckboxGroup
                            id="documentSelect"
                            classes="form-control"
                            legendLabel="Document type"
                            groupFormControlId="documentSelection"
                            errorMessages={["Select a document type to continue."]}
                            isValid={isvalidDocument}
                            checkboxItems={[
                                {
                                    isChecked: downloadObject.checks,
                                    handleOnChange: (e: Event) => handleOnChangeDocuments(e, 'checks'),
                                    label: "Checks",
                                    size: "small",

                                },
                                {
                                    isChecked: downloadObject.documents,
                                    handleOnChange: (e: Event) => handleOnChangeDocuments(e, 'documents'),
                                    label: "Documents",
                                    size: "small"
                                }
                            ]}
                        />
                        <div className='dropdown-divider'></div>
                        <div className='radio-groups'>
                            <RadioGroup
                                groupFormControlId="fileSelect"
                                legendLabel="File type"
                                name="radio-group-file"
                                size="small"
                                errorMessages={["Select a file type to continue."]}
                                isValid={isvalidFile}
                                radioItems={[
                                    {
                                        handleOnChange: () => { handleOnChangeFileType("PDF") },
                                        inputId: 'radio-daily-alerts',
                                        isChecked: downloadObject.fileType === "PDF",
                                        label: 'PDF',
                                        value: "PDF",
                                        size: 'small'
                                    },
                                    {
                                        handleOnChange: () => { handleOnChangeFileType("Zip") },
                                        inputId: 'radio-event-alert',
                                        isChecked: downloadObject.fileType === "Zip",
                                        label: 'Zip file',
                                        value: "Zip",
                                        size: 'small'
                                    }
                                ]}
                            />
                        </div>
                        <Button
                            classes="download-button"
                            size="medium"
                            text="Download"
                            clickMethod={() => { handleDownloadTransactions() }}
                        />
                    </DropdownMenuContent>
                </DropdownMenu>  
                :
                <></>
                }
            </FilterForm>
            <div className='batch-summary-table'>
            <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
                    {data_summary && data_summary.items && data_summary.items.length > 0 ?
                        <>
                            <Table
                                ariaDescribedBy={null}
                                caption={`Batch summary ${tableCaptionDates.end !== tableCaptionDates.start ? `from` : `for` } ${tableCaptionDates.start} ${tableCaptionDates.end !== tableCaptionDates.start ? `to ${tableCaptionDates.end}` : ``}` }
                                columnData={columns}
                                data={data_summary.items}
                                showSupplementalStatus
                                supplementalStatus={`${selectedRows.filter(val => val === true).length} of ${data_summary.items.length} ${data_summary.items.length > 1 ? 'rows' : 'row'} selected for download`}
                                tableRowComponents={rowComponents}
                            />
                        </>
                        :
                        <p className='zero-state'>No result found. Please set filters and try again.</p>
                    }
                <ModalsContainer>
                    <CustomModal
                        okType ModalText='Please enter a date range up to 90 days.'
                        ModalTitle='Incorrect date range'
                        ModalIsOpen={showValidationDateDiff}
                        CloseModal={() => { setShowValidationDateDiff(false) }} />
                    <CustomModal
                        okType
                        ModalText="Please select one or more transactions before proceeding to download."
                        ModalTitle="No transactions selected"
                        ModalIsOpen={transactionNotSelected}
                        CloseModal={() => {
                            setTransactionNotSelected(false);
                        }}
                    />
                    <CustomModal
                        downloadReports
                        ModalText="Your report has been requested and will be available in Reports."
                        ModalTitle="Request submitted"
                        ModalIsOpen={modalIsOpenPDF}
                        CloseModal={() => {
                            setModalIsOpenPDF(false)
                        }}
                    />
                    <CustomModal
                        downloadReports
                        ModalText="Your report has been requested and will be available in Reports."
                        ModalTitle="Request submitted"
                        ModalIsOpen={modalIsOpenZip}
                        CloseModal={() => {
                            setModalIsOpenZip(false)
                        }}
                    />
                </ModalsContainer>
            </div>
            </>
            :
            <Loader />
            }
        </main>
    )
}
export default BatchSummary;