import requests from "./apiRequest";
import { BatchSummaryReportRequest, IBatchSummary_req_body } from "../interfaces/IBatchSummary";

const BatchdataSummary = {
    details: async (body: IBatchSummary_req_body) => {
        try {
            let result = await requests.getParams('summaries/batch-summary', body);
            return result;
        } catch (error) {
            console.log('error from lockbox api integration: ', error);
        }
    },

    generateReport: async (body: BatchSummaryReportRequest) => {
        let result = await requests.post("summaries/batch-summary/report", body);
        return result;
    },

    // New method for skipping all transactions in a batch
    skipAllTransactionsInBatch: async (batchId: number) => {
        try {
            let result = await requests.post(`batches/${batchId}/skip-transactions`, {});
            return result;
        } catch (error) {
            console.log('Error while skipping all transactions in the batch: ', error);
        }
    }
}

export default BatchdataSummary;
