/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useRef, FormEvent } from "react";
import { useNavigate } from 'react-router-dom-v6';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import dateformat from "dateformat";
// @ts-ignore
import { Accordion, AccordionHeader, AccordionItem, AccordionPane, Button, Tile, Select, Textarea, Checkbox, CheckboxGroup, DropdownMenu, DropdownTrigger, DropdownMenuContent } from '@ux/balance-react';
// @ts-ignore
import { IconClose } from '@ux/balance-icons';
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { ExceptionTransactionImageIndex, ExceptionLastFocusedImageBtn, ExceptionBatchDetailRes, ExceptionTransactionCurrentPage, exception_lockbox_code, exception_transaction_ID, ExceptionTransactionForcedPage, AdditionalPaymentExceptionSetup, userState, Client_code, Exception_Banker_CurrentTab } from '../atom';
import { ExceptionSetupRes, getExceptionSetup } from "../API_Integrations/exceptionSetup";
import { getExceptionTransactionDetail, IFields, createExceptionTransaction, CreateExceptionTransactionDetail, Exception_Transaction_AdditionalData, UnlockExceptionTransaction, unlockExceptionTransaction, GenerateExceptionTransactionDetailReport } from "../API_Integrations/exceptionTransactionDetail";
import ClientHeader from "../components/ClientHeader";
import ExceptionMirror from "../components/ExceptionMirror";
import ExceptionImageViewer from "../components/ExceptionImageViewer";
import Loader from "../components/Loader";
import CardContainer from "../components/CardContainer";
import CardData from "../components/CardData";
import CustomPagination from "../components/CustomPagination";
import ModalsContainer from "../components/ModalsContainer";
import CustomModal from "../components/CustomModal";
import AuthenticatedImage from '../components/AuthenticatedImage';
import { useTitle } from "../utils/use-title";
import { replaceImages } from "../utils/image-utils";
import { Exception } from "sass";

interface Additional_DetailData {
    itemSequenceNumber: number
    exceptionDocumentId: null | number
    fields: IFields[]
}

interface PageChangeData {
    selected: number
}

interface EmptyObject {
    [key: string]: { id: number, key: string, value: string }[]
}

interface ErrorMessage { ariaLabel: string, text: string, inputId: string }

function ExceptionTransactionDetail() {
    useTitle("Exception transaction detail");

    enum TransactionState { View, Edit }
    const [data_batch_detail, setdata_batch_detail] = useRecoilState(ExceptionBatchDetailRes);
    const [exceptionSetupResponse, setExceptionSetupResponse] = useState<ExceptionSetupRes>();
    const [transaction_current_page, setTransaction_current_page] = useRecoilState(ExceptionTransactionCurrentPage);
    const [forcedPage, setForcedPage] = useRecoilState(ExceptionTransactionForcedPage)
    const [currentItemIndex, setcurrentItemIndex] = useRecoilState(ExceptionTransactionImageIndex);
    const [lastFocusedBtn, setLastFocusedBtn] = useRecoilState(ExceptionLastFocusedImageBtn);
    const [transaction_ID, setTransaction_ID] = useRecoilState(exception_transaction_ID);
    const [additional_payment_details, setadditional_payment_details] = useRecoilState(AdditionalPaymentExceptionSetup);
    const setBankerExceptionCurrentTab = useSetRecoilState(Exception_Banker_CurrentTab);
    const exception_lockboxCode = useRecoilValue(exception_lockbox_code);
    const user = useRecoilValue(userState);
    const clientCode = useRecoilValue(Client_code);
    const [loading, setLoading] = useState(false);
    const [loadingExceptions, setLoadingExceptions] = useState(false);
    const [manageState, setManageState] = useState(TransactionState.Edit);
    const [isEnlarge, setIsEnlarge] = useState(false);
    const [pageCount, setPageCount] = useState(data_batch_detail.batchItems.length);
    const [forceImagePagination, setForceImagePagination] = useState(false);
    const [closeButtonFocus, setCloseButtonFocus] = useState(false);
    const [transactionStatusForm, setTransactionStatusForm] = useState({ status: '', escalateTo: '', comment: '', escalateToName: '' });
    const [downloadDocument, setDownloadDocument] = useState({ documents: false, checks: false });
    const [isvalidDocument, setIsvalidDocument] = useState(true);
    const [errorMessagesSummary, seterrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [showLockedTransactionModal, setShowLockedTransactionModal] = useState({ show: false, userName: '' });
    const [remountKey, setRemountKey] = useState(0)
    const [saveErrorModalOpen, setSaveErrorModalOpen] = useState(false);
    const [deletedPaymentIds, setDeletedPaymentIds] = useState<number[]>([]);
    const [isValidEscalatedValue, setIsValidEscalatedValue] = useState(true);
    const [isInBalance, setIsInBalance] = useState(false);
    const [enlargeImage, setEnlargeImage] = useState(false);
    const [modalIsOpenPDF, setModalIsOpenPDF] = useState(false);
    const imageViewerRefs: { [key: string]: { iconButtonRef: React.RefObject<HTMLButtonElement>, imageButtonRef: React.RefObject<HTMLButtonElement> } } = {
        'exceptionImg': { iconButtonRef: useRef<HTMLButtonElement | null>(null), imageButtonRef: useRef<HTMLButtonElement | null>(null) }
    }
    const [getCorpCodeList, setCorpCodeList] = useState([{
        label: '',
        value: '',
    }]);
    const [getCustomDropdownList, setCustomDropdownList] = useState([{
        label: '',
        value: '',
    }]);
    const [getCustomDropdownHeader, setCustomDropdownHeader] = useState('');
    const initialHeadingRef = useRef<HTMLHeadingElement | null>(null);
    const navigate = useNavigate();
    
    const currentPage = transaction_current_page;
    const current_page_transaction_id = data_batch_detail.batchItems[currentPage].id;
    useEffect(() => {
        const fetchData = async () => {
           await getExceptionSetupAPI();
        }
      
        fetchData();
    }, []);
    const [itemData, setItemData] = useState({
        details: { status: '', comment: '', postCutoffTime: false },
        data: [{ image: '' }, { image: '' }, { image: '' }],
        name: '', date: '',
        id: '',
        transactionId: 0,
        amount: 0,
        exceptionTransactionSequenceNo: 0,
        batchNumber: '',
        totalExceptions: 0,
        outstanding: 0,
        completed: 0,
        returned: 0,
        escalated: 0,
        rejected: 0,
        additional_data: [{
            itemSequenceNumber: 1,
            exceptionDocumentId: null,
            fields: additional_payment_details
        }, {
            itemSequenceNumber: 2,
            exceptionDocumentId: 2,
            fields: additional_payment_details
        }]
    });

    const status_options = [
        {
            label: 'Pending',
            value: "Pending"
        },
        {
            label: 'Complete',
            value: "Completed"
        },
        {
            label: 'Return',
            value: "Returned"
        },
        {
            label: 'Reject',
            value: "Rejected"
        },
        {
            label: 'Escalate to',
            value: "Escalated"
        },
    ];

    const [escalate_options, setEscalate_options] = useState([
        {
            label: 'Option 1',
            value: 'Option1'
        },
        {
            label: 'Option 2',
            value: 'Option2'
        },
        {
            label: 'Option 3',
            value: 'Option3'
        },
    ]);


    useEffect(() => {
        if (enlargeImage) {
            closeEnlargeButtonRef.current?.focus();
        } else {
            for (const id in lastFocusedBtn) {
                if (lastFocusedBtn[id] === 'icon') {
                    imageViewerRefs[id].iconButtonRef.current?.focus();
                } else if (lastFocusedBtn[id] === 'image') {
                    imageViewerRefs[id].imageButtonRef.current?.focus();
                }
            }

        }
    }, [enlargeImage, lastFocusedBtn]);

    useEffect(() => {
        initialHeadingRef.current?.focus();
    }, [remountKey]);
    useEffect(() => {
        replaceImages();
    }, [currentItemIndex, isEnlarge, enlargeImage, loadingExceptions, forcedPage, transaction_ID]);

    const handleImagePageClick = async (item: any) => {
        if (item.selected !== undefined || forceImagePagination) {
            setcurrentItemIndex(item.selected);
            setForceImagePagination(false);
        }
    }

    const handleOpenEnlargeImage = (id: string) => {
        setLastFocusedBtn((prev) => ({ ...lastFocusedBtn, [id]: null }));
        setEnlargeImage(true);
        setIsEnlarge(true);
    }

    const handleCloseEnlargeImage = () => {
        setIsEnlarge(false);
        setEnlargeImage(false);
        setCloseButtonFocus(true);
    }

    const closeEnlargeButtonRef = useRef<HTMLButtonElement | null>(null);

    const handleOnChangeSelectStatus = (event: Event) => { setTransactionStatusForm({ ...transactionStatusForm, status: (event.target as HTMLInputElement).value }) }

    const handleOnChangeSelectEscalateTo = (event: Event) => { setTransactionStatusForm({ ...transactionStatusForm, escalateTo: (event.target as HTMLInputElement).value }) }

    const handleOnChangeComment = (event: Event) => { setTransactionStatusForm({ ...transactionStatusForm, comment: (event.target as HTMLInputElement).value }) }

    const handleOnChangePaymentDetails = (iPaymentdetail: number, iAdditionalDetail: number, id: number, event: Event) => {
        const itemValue = (event.target as HTMLInputElement).value;
        const currentFields = itemData['additional_data'][iAdditionalDetail].fields;

        const updatedFields = currentFields.map(field => {
            if (field.id === id) {
                return { ...field, value: itemValue }
            }
            return field;
        });

        const updatedData = {
            ...itemData,
            additional_data: itemData.additional_data.map((item, index) => {
                if (index === iAdditionalDetail) {
                    return {
                        ...item,
                        fields: updatedFields
                    }
                }
                return item;
            })
        }

        setItemData(updatedData);
    }

    const onClickDeletePaymentDetail = (i: number) => {
        const itemDeleted = i;
        const deletedIndex = deletedPaymentIds.indexOf(i);

        if (deletedIndex === -1) {
            const sortedDeletedIds = [...deletedPaymentIds, itemDeleted].sort((a, b) => a - b);
            setDeletedPaymentIds(sortedDeletedIds);
        }

        const updatedFiellds = itemData.additional_data.filter(field => field.itemSequenceNumber !== itemDeleted);
        setItemData(prevItem => ({
            ...prevItem, additional_data: updatedFiellds
        }));
    }

    const onClickAddPaymentDetail = () => {
        const newItemSequenceNo = itemData.additional_data.length + 1;
        const availableDeletedIds = deletedPaymentIds;

        if (availableDeletedIds.length > 0) {
            const newItemSequenceNo = availableDeletedIds[0];
            setItemData(prevItem => ({
                ...prevItem,
                additional_data: [...prevItem.additional_data, {
                    itemSequenceNumber: newItemSequenceNo,
                    exceptionDocumentId: null,
                    fields: additional_payment_details
                }]
            }));
            setDeletedPaymentIds(availableDeletedIds.filter(id => id !== newItemSequenceNo));
        } else {
            setItemData(prevItem => ({
                ...prevItem,
                additional_data: [...prevItem.additional_data, {
                    itemSequenceNumber: newItemSequenceNo,
                    exceptionDocumentId: null,
                    fields: additional_payment_details
                }]
            }));
        }
    }

    const getExceptionSetupAPI = async () => {
        const LockboxCode = exception_lockboxCode;
        try {
            setLoading(true);
            const res = await getExceptionSetup(LockboxCode);
            setExceptionSetupResponse(res);
            let initialAccount: IFields[] = [];
            let initialAmount: IFields[] = [];
            let currentId = 0;
            let CustomAccountFields: IFields[] = [];
            let CustomAmountFields: IFields[] = [];
            let customDropdownFields = res.customDropdownFields?.length ?? 0;
            if (res.accountFieldName) {
                initialAccount = [{ id: currentId++, fieldType: "Account", key: res.accountFieldName, value: '', position: res.accountFieldSelection }]
            }

/*            if (res.customAccountFields && ((res.customDropdownLabel !== '' || undefined) && customDropdownFields !== 0)) {
                CustomAccountFields = res.customAccountFields ? res.customAccountFields.map((field) => ({
                    id: currentId++, fieldType: "Account", key: field.fieldName ? field.fieldName : '', value: '', position: field.fieldSelection
                }
                )) : []
                setCustomDropdownHeader(res.customDropdownLabel ?? '');
            } else */if (res.customAccountFields) {
                CustomAccountFields = res.customAccountFields ? res.customAccountFields.filter((field)=> field.fieldName !== res.customDropdownLabel).map((field) => ({
                    id: currentId++, fieldType: "Account", key: field.fieldName ? field.fieldName : '', value: '', position: field.fieldSelection
                }
                )) : []
            }
            if (res.amountFieldName) {
                initialAmount = [{ id: currentId++, fieldType: "Amount", key: res.amountFieldName, value: '', position: res.amountFieldSelection }]
            }
            if (res.customAmountFields) {
                CustomAmountFields = res.customAmountFields ? res.customAmountFields.map((field) => ({
                    id: currentId++, fieldType: "Amount", key: field.fieldName ? field.fieldName : '', value: '', position: field.fieldSelection
                })) : []
            }

            let paymentDetails = [...initialAccount, ...CustomAccountFields, ...initialAmount, ...CustomAmountFields]
            setadditional_payment_details(paymentDetails);
            let corpCodeArray = Array.isArray(res.corpCodes) && res.corpCodes.length > 0
                ? res.corpCodes?.map((field) => ({
                    label: field.fieldDescription!,
                    value: field.fieldName!,
                })) : [];
            corpCodeArray.unshift({ label: "", value: "" })
            setCorpCodeList(corpCodeArray)

            let customDropdownArray = Array.isArray(res.customDropdownFields) && res.customDropdownFields.length > 0
                ? res.customDropdownFields?.map((field) => ({
                    label: field.fieldDescription!,
                    value: field.fieldName!,
                })) : [];
            customDropdownArray.unshift({ label: "", value: "" });
            setCustomDropdownList(customDropdownArray);
        } catch {
            console.log('error in getting exception setup API')
        } finally {
            setLoading(false);
        }
    }

    const adjustExceptionTransactionAdditionalDataFields = (exceptionTransactionAdditionalData: Exception_Transaction_AdditionalData | undefined): IFields[] => {
        let customDropdownFields = exceptionSetupResponse?.customDropdownFields?.length ?? 0;

        if (exceptionTransactionAdditionalData === undefined || exceptionTransactionAdditionalData.exceptionDetails?.length === 0) {
            return additional_payment_details
        }
        var fields = exceptionTransactionAdditionalData.exceptionDetails.map((details, detailIndex) => ({ id: detailIndex + 1, fieldType: details.fieldType, key: details.key, value: details.value, position: details.position }))

        if (
            exceptionSetupResponse?.corpCodeLabel === "Corp code" &&
            !fields.some((x) => x.key === "Corp code")
        ) {
            fields = fields.concat({ id: fields.length + 1, fieldType: "Account", key: exceptionSetupResponse.corpCodeLabel, value: '', position: exceptionSetupResponse.corpCodeField})
        }

/*        if (((exceptionSetupResponse?.customDropdownLabel !== '' || undefined) && customDropdownFields !== 0) && fields.some((x) => x.key === exceptionSetupResponse?.customDropdownLabel)) {

        } else {
            fields = fields.concat({ id: fields.length + 1, fieldType: "Account", key: exceptionSetupResponse?.customDropdownLabel ?? '', value: '', position: exceptionSetupResponse?.customDropdownAccountField })
        }*/

        // Push account fields before amount fields.
        const accountFieldName = exceptionSetupResponse?.accountFieldName
        const customAccountFieldNames = exceptionSetupResponse?.customAccountFields?.map(field => field.fieldName)
        const corpCodeFieldName = exceptionSetupResponse?.corpCodeLabel
        const amountFieldName = exceptionSetupResponse?.amountFieldName
        const customAmountFieldNames = exceptionSetupResponse?.customAmountFields?.map(field => field.fieldName)

        fields = fields.sort((a, b) => {
            if (a.fieldType === "Account" && b.fieldType === "Amount") {
                return -1;
            } else if (a.fieldType === "Amount" && b.fieldType === "Account") {
                return 1;
            } else if (a.fieldType === "Account" && a.key === corpCodeFieldName && b.fieldType === "Account" && b.key !== accountFieldName) {
                return -1;
            } else {
                return 0;
            }
        })

        return fields
    }

    const getExceptionTransactionDetailAPI = async (transactionID: number) => {
        try {
            setLoadingExceptions(true);
            const transaction_detail = await getExceptionTransactionDetail(transactionID);
            const transactionLockedById = transaction_detail.lockedByUserId !== undefined ? transaction_detail.lockedByUserId : undefined;
            const transactionLockedByName = transaction_detail.lockedByUserName !== undefined ? transaction_detail.lockedByUserName : 'None';
            if (transaction_detail.isPostCutoffTime) {
               setManageState(TransactionState.View);
            } else if ((transaction_detail.transactionStatus === "Returned") || (transaction_detail.transactionStatus === "Rejected")) {
                setManageState(TransactionState.View);
            } else if ((transactionLockedById !== undefined) && (user.id !== transactionLockedById) && (transaction_detail.transactionStatus === "Pending")) {
                setShowLockedTransactionModal({ show: true, userName: transactionLockedByName });
                setManageState(TransactionState.View);
            } else if ((transaction_detail.transactionStatus === "Escalated") && transaction_detail.escalateTo !== user.id) {
                setManageState(TransactionState.View);
            } else if (transaction_detail.transactionStatus === "Escalated" && transaction_detail.escalateTo === user.id) {
                setManageState(TransactionState.Edit);
            } else if ((transactionLockedById !== undefined) && (user.id === transactionLockedById) && (transaction_detail.transactionStatus === "Pending")) {
                setManageState(TransactionState.Edit);
            } else if (transaction_detail.transactionStatus === "Completed") {
                setManageState(TransactionState.Edit);
            } else {
                setManageState(TransactionState.View);
            }
            const images = transaction_detail.transactionImages ? transaction_detail.transactionImages.map((item) => ({ image: item })) : [];
            
            var additionalData = (transaction_detail.exceptionDocuments && transaction_detail.exceptionDocuments.length > 0) ? transaction_detail.exceptionDocuments.map((documents, index) => {
                return {
                    itemSequenceNumber: index + 1,
                    exceptionDocumentId: documents.exceptionDocumentId !== undefined && documents.exceptionDocumentId !== null ? documents.exceptionDocumentId : null,
                    fields: adjustExceptionTransactionAdditionalDataFields(documents)
                    //fields: documents.exceptionDetails.map((details, detailIndex) => ({ id: detailIndex + 1, fieldType: details.fieldType, key: details.key, value: details.value, position: details.position }))
                }
            }) : [{
                itemSequenceNumber: 1,
                exceptionDocumentId: null,
                fields: additional_payment_details
            }];
            
            const amountFieldIndex = additionalData[0].fields.findIndex(field => field.fieldType === 'Amount');
            if (amountFieldIndex !== -1 && (additionalData[0].fields[amountFieldIndex].value === '' || additionalData[0].fields[amountFieldIndex].value === '0')) {
                additionalData[0].fields[amountFieldIndex].value = String(transaction_detail.amount);
            }
            const escaletToUserId = (transaction_detail.escalateTo !== undefined) ? String(transaction_detail.escalateTo) : 'None';
            const escaletToUserName = (transaction_detail.escalateToUsers && transaction_detail.escalateTo !== undefined) ? (transaction_detail.escalateToUsers.find((user) => (user.userId === transaction_detail.escalateTo)) !== undefined) ? String(transaction_detail.escalateToUsers.find((user) => (user.userId === transaction_detail.escalateTo))?.userName) : 'Not found' : 'None';

            setItemData({
                details: { status: transaction_detail.transactionStatus, comment: transaction_detail.transactionComment ? transaction_detail.transactionComment : '', postCutoffTime: transaction_detail.isPostCutoffTime }, data: images,
                name: transaction_detail.remitterName ? transaction_detail.remitterName : '', date: transaction_detail.depositDate,
                id: transaction_detail.lockboxCode,
                amount: transaction_detail.amount, 
                exceptionTransactionSequenceNo : transaction_detail.exceptionTransactionSequenceNo,
                transactionId: transaction_detail.exceptionTransactionId,
                batchNumber: transaction_detail.batchNumber ? transaction_detail.batchNumber : '',
                totalExceptions: transaction_detail.totalExceptions,
                outstanding: transaction_detail.outstandingExceptions,
                completed: transaction_detail.completedExceptions,
                returned: transaction_detail.returnedExceptions,
                escalated: transaction_detail.escalatedExceptions,
                rejected: transaction_detail.rejectedExceptions,
                additional_data: additionalData
            });

            const transation_users = [...[{ label: 'None', value: 'None' }], ...(transaction_detail.escalateToUsers ? transaction_detail.escalateToUsers.map((user) => ({ label: user.userName, value: String(user.userId) })) : [])];
            setEscalate_options(transation_users);
            setTransactionStatusForm({ status: transaction_detail.transactionStatus, escalateTo: escaletToUserId, comment: transaction_detail.transactionComment ? transaction_detail.transactionComment : '', escalateToName: escaletToUserName });

        } catch (error) {
            console.log(error)
        } finally {
            setLoadingExceptions(false);
        }

    }

    const iterateToNextPage = async (id: number) => {
        const currentPage = transaction_current_page;
        const next_page_transaction_id = data_batch_detail.batchItems[currentPage + 1].id;
       
        try {
            const currentPage = transaction_current_page;
            await getExceptionTransactionDetailAPI(next_page_transaction_id);
            setTransaction_ID(next_page_transaction_id);
            setForcedPage(currentPage + 1);
            navigate(`/exception-transaction-detail/${next_page_transaction_id}`);


            setRemountKey((prevKey) => prevKey + 1);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    console.log('bad request 400')
                } else {
                    setSaveErrorModalOpen(true);
                }
            }
            console.log(error)

        } finally {
            setLoadingExceptions(false);
        }
    }

    const reloadPage = async (id: number) => {
        const currentPage = transaction_current_page;
        const page_transaction_id = data_batch_detail.batchItems[currentPage].id;

        try {

            const currentPage = transaction_current_page;
            await getExceptionTransactionDetailAPI(page_transaction_id);
            setTransaction_ID(page_transaction_id);
            setForcedPage(currentPage);
            navigate(`/exception-transaction-detail/${page_transaction_id}`);

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    console.log('bad request 400')
                } else {
                    setSaveErrorModalOpen(true);
                }
            }
            console.log(error)

        } finally {
            setLoadingExceptions(false);
        }
    }
    const SaveExceptionTransactionDetail = async (id: number) => {
        const additionalData = itemData.additional_data.map((item) => ({exceptionDocumentId: item.exceptionDocumentId, exceptionDetails: item.fields.map(({ id, ...rest }) => rest) }))
        const body: CreateExceptionTransactionDetail = {
            status: transactionStatusForm.status,
            comment: transactionStatusForm.comment,
            sequenceNumber: itemData.exceptionTransactionSequenceNo.toString(),
            exceptionDocuments: additionalData
        }

        if (transactionStatusForm.status === "Escalated") {

            body.escalatedUserId = Number(transactionStatusForm.escalateTo)
        }

        const result = await createExceptionTransaction(id, body);  
    }

    const runUnlockExceptionTransactionApi = async () => {

        const body: UnlockExceptionTransaction = {
            exceptionTransactionId: current_page_transaction_id
        }
        await unlockExceptionTransaction(current_page_transaction_id, body);
    }
    const handlePageClick = async (data: PageChangeData) => {

        setLoadingExceptions(true);
        setcurrentItemIndex(0);
        if (data.selected !== undefined) {
            runUnlockExceptionTransactionApi();
            setForceImagePagination(true);
            const transaction_id = data_batch_detail.batchItems[data.selected].id;
            await getExceptionTransactionDetailAPI(transaction_id);
            setTransaction_current_page(data.selected);

            navigate(`/exception-transaction-detail/${transaction_id}`);
            setRemountKey((prevKey) => prevKey + 1);
        } else {
            //setNotFoundModalOpen(true);
            setLoadingExceptions(false);
        }
    }

    const handleSubmit = async () => {
        if (transactionStatusForm.status === "Escalated" && transactionStatusForm.escalateTo === 'None') {
            setIsValidEscalatedValue(false);
            return;
        }
        setLoadingExceptions(true);
        setcurrentItemIndex(0);
        try {
            const currentPage = transaction_current_page
            setForceImagePagination(true);
            const transaction_id = data_batch_detail.batchItems[currentPage].id;
            if ((pageCount - 1) === currentPage) {
                await SaveExceptionTransactionDetail(transaction_id);
                handleReturnToExceptionSummary();
            } else {
                const next_page_transaction_id = transaction_id + 1;
                setForceImagePagination(true);
                await SaveExceptionTransactionDetail(transaction_id);
                iterateToNextPage(transaction_ID);
                setTransaction_ID(next_page_transaction_id);
                setTransaction_current_page(currentPage + 1);
            }
        } catch {
            
            const currentPage = transaction_current_page;
            const transaction_id = data_batch_detail.batchItems[currentPage].id;
            setIsInBalance(true);
            reloadPage(transaction_ID);
            setTransaction_ID(transaction_id);
            setTransaction_current_page(currentPage);
            
        }
    }

    const handleHeadingFocus = (ref: React.RefObject<HTMLHeadingElement>) => {
        if (!closeButtonFocus) {
            initialHeadingRef.current = ref.current;
        }
    }

    const handleOnChangeDocuments = (event: Event, documentName: string) => {
        const newVal = (event.target as HTMLInputElement).checked;
        if (downloadDocument.hasOwnProperty(documentName)) {
            setDownloadDocument({ ...downloadDocument, [documentName]: newVal });
        }
    };

    const handledownloadImage = () => {
        generateReport();
        
        setModalIsOpenPDF(true);
    }

    const handleReturnToExceptionSummary = () => {
        if (user.isBanker) {
            runUnlockExceptionTransactionApi();
            navigate(`/banker-lockbox-summary/${clientCode}`);
            setBankerExceptionCurrentTab('EXCEPTIONMANAGEMENT');
        } else {
            runUnlockExceptionTransactionApi();
            navigate(`/exception-batch-summary/${exception_lockboxCode}`);
            setBankerExceptionCurrentTab('EXCEPTIONMANAGEMENT');
        }

    }
    const generateReport = () => {
        let currentPage = transaction_current_page
        let id = data_batch_detail.batchItems[currentPage].id;
        GenerateExceptionTransactionDetailReport(id, {exceptionTransactionId: id});
    }

    if (loading) {
        return <Loader isLoading={loading} />
    }

    return (
        <main>
            <ClientHeader showClientName={true} showExceptionsBreadcrumbs={true} exceptionTransactionId = {current_page_transaction_id}/>
            <div className='loader-section'>
                <Loader transactionLoader isLoading={loadingExceptions} />
                <div className={loadingExceptions ? 'custom-loader-hidden' : 'custom-loader-visible'}>
                    <ExceptionMirror
                        exceptionTransactionDetail={true}
                        onHeadingFocus={handleHeadingFocus}
                        name={itemData.name}
                        date={dateformat(itemData.date, "mm/dd/yyyy")}
                        id={itemData.id}
                        transactionId={itemData.exceptionTransactionSequenceNo}
                        batchNumber={itemData.batchNumber}
                        totalExceptions={itemData.totalExceptions}
                        outstanding={itemData.outstanding}
                        completed={itemData.completed}
                        returned={itemData.returned}
                        escalated={itemData.escalated}
                        rejected={itemData.rejected}
                    />
                    <div className='transaction-tile-container'>
                        <>

                            <Tile
                                classes=""
                                dataTestId={null}
                                isPadded
                            >

                                <div className={isEnlarge ? 'hide-block' : ''}>
                                    {(itemData) ?
                                        <>
                                            {((itemData.details)) ?


                                                <div className='inner-container'>

                                                    <div className='exception-card'>
                                                        <p className='card-title exception-card-title'>Transaction {itemData.details.postCutoffTime ? 'post cutoff time' : 'status'}</p>
                                                        {manageState === TransactionState.View ?
                                                            <div>
                                                                <CardData title='Status' text={transactionStatusForm.status} />
                                                                {transactionStatusForm.status === 'Escalated' && <CardData title='Escalate to' text={transactionStatusForm.escalateToName} />}
                                                                <CardData title='Comment' text={transactionStatusForm.comment} />
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className="exception-form-controls">
                                                                    <Select
                                                                        label="Status"
                                                                        name="Select status"
                                                                        size='small'
                                                                        classes='width-258'
                                                                        options={status_options}
                                                                        handleOnChange={handleOnChangeSelectStatus}
                                                                        value={transactionStatusForm.status}
                                                                    />
                                                                </div>
                                                                {transactionStatusForm.status === 'Escalated' && <div className="exception-form-controls">
                                                                    <Select
                                                                        label="Escalate to"
                                                                        name="Select escalate to"
                                                                        size='small'
                                                                        classes='width-258'
                                                                        options={escalate_options}
                                                                        handleOnChange={handleOnChangeSelectEscalateTo}
                                                                        value={transactionStatusForm.escalateTo}
                                                                        errorMessages={["Select a user from the list above."]}
                                                                        isValid={isValidEscalatedValue}
                                                                    />
                                                                </div>
                                                                }
                                                                <div className="exception-form-controls">
                                                                    <Textarea
                                                                        label="Comment"
                                                                        value={transactionStatusForm.comment}
                                                                        size='small'
                                                                        handleOnChange={handleOnChangeComment}
                                                                        type="text"
                                                                        classes='width-258'
                                                                    />
                                                                </div>
                                                            </div>

                                                        }

                                                    </div>


                                                    {(itemData.data && itemData.data.length > 0) && <div className='exceptions-image-card'>

                                                        <ExceptionImageViewer
                                                            imgSource={`${itemData.data[currentItemIndex]?.image}`}
                                                            altImageText={`Check ${currentItemIndex + 1}`}
                                                            id="exceptionImg"
                                                            onClickEnlarge={() => { handleOpenEnlargeImage("exceptionImg") }}
                                                            imageButtonRef={imageViewerRefs["exceptionImg"]?.imageButtonRef}
                                                            iconButtonRef={imageViewerRefs["exceptionImg"]?.iconButtonRef}
                                                            exceptionTypeChildren={<div>
                                                                <ModalsContainer>
                                                                    <CustomModal
                                                                        downloadIconButton={true}
                                                                        showClose={true}
                                                                        ButtonTitle='Download report as PDF'
                                                                        ModalIsOpen={modalIsOpenPDF}
                                                                        OpenModal={handledownloadImage}
                                                                        CloseModal={() => { setModalIsOpenPDF(false) }}
                                                                        ModalText='Your report has been requested and will be available in Reports.'
                                                                        ModalTitle='Request submitted'
                                                                    />
                                                                </ModalsContainer>
                                                            </div>}
                                                        >
                                                            <div id='test-related-check' className='image-viewer-pagination'>
                                                                <CustomPagination gotoTransaction={false}>
                                                                    <ReactPaginate
                                                                        className={isEnlarge ? 'hide-block' : ''}
                                                                        nextLabel={<span>Next<span className='page-next-icon'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                                                                <path d="M2.27015 0.233855C1.91055 -0.045751 1.39059 -0.0203322 1.06014 0.310111C0.702161 0.668092 0.702161 1.24849 1.06014 1.60647L5.45363 5.99996L1.06014 10.3934L0.983886 10.4798C0.70428 10.8394 0.729698 11.3594 1.06014 11.6898C1.41812 12.0478 1.99852 12.0478 2.3565 11.6898L7.39817 6.64814C7.42571 6.62061 7.45112 6.59175 7.47442 6.56179C7.48899 6.54306 7.50273 6.52389 7.51563 6.50434C7.75056 6.14854 7.7114 5.66501 7.39817 5.35178L2.3565 0.310111L2.27015 0.233855Z" fill="#707070" />
                                                                            </svg></span></span>}
                                                                        onPageChange={handleImagePageClick}
                                                                        pageRangeDisplayed={3}
                                                                        marginPagesDisplayed={1}
                                                                        pageCount={itemData.data.length}
                                                                        previousLabel={
                                                                            <span><span className='page-previous-icon'><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                                                                <path d="M6.93985 0.310111C6.58187 -0.0478692 6.00146 -0.0478692 5.64348 0.310111L0.601818 5.35178C0.574281 5.37932 0.548862 5.40817 0.525561 5.43814C0.245955 5.79774 0.271374 6.3177 0.601818 6.64814L5.64348 11.6898L5.72984 11.7661C6.08944 12.0457 6.6094 12.0203 6.93985 11.6898C7.29783 11.3318 7.29783 10.7514 6.93985 10.3934L2.54636 5.99996L6.93985 1.60647L7.0161 1.52012C7.29571 1.16052 7.27029 0.640555 6.93985 0.310111Z" fill="#707070" />
                                                                            </svg>
                                                                            </span>&nbsp;Previous</span>
                                                                        }
                                                                        pageLinkClassName={"cta cta--text-alternate cta--medium"}
                                                                        previousClassName=""
                                                                        previousLinkClassName={"cta cta--text-alternate cta--medium"}
                                                                        nextClassName=""
                                                                        nextLinkClassName={"cta cta--text-alternate cta--medium"}
                                                                        breakLabel="..."
                                                                        breakClassName={"pagination__ellipses"}
                                                                        breakLinkClassName={"break-text"}
                                                                        breakAriaLabels={{
                                                                            forward: "forward break",
                                                                            backward: "backward break"
                                                                        }}
                                                                        pageClassName={""}
                                                                        containerClassName={"pagination__list"}
                                                                        activeClassName={""}
                                                                        activeLinkClassName={"cta cta--text-alternate cta--medium cta--active"}
                                                                        renderOnZeroPageCount={null}
                                                                        disabledLinkClassName={"pagination__prev-next--display"}
                                                                        disabledClassName={"disable-link-pagination-list"}
                                                                        initialPage={currentItemIndex}
                                                                        forcePage={forceImagePagination ? 0 : undefined}
                                                                    />
                                                                </CustomPagination>
                                                            </div>
                                                        </ExceptionImageViewer>
                                                    </div>}
                                                </div>
                                                :
                                                <></>
                                            }

                                            {(itemData.additional_data.length > 0) ?
                                                <div className=''>
                                                    <div className='card-divider'></div>
                                                    <CardContainer additional_card>
                                                        <div className='additional-card'>
                                                            <p className='card-title'>Additional payment details</p>
                                                            <div className='additional-details-note top-gap-8 '>
                                                                <p>Note: The information below may not be directly related to the image above. You may have up to 100 payment details.</p>
                                                            </div>
                                                            <div className='additional-details-accordions'>
                                                                <Accordion
                                                                    setOpen={[]}
                                                                    withLeftChevron={true}
                                                                    showExpandCollapse
                                                                    titleLevel={3}
                                                                >
                                                                    {itemData.additional_data.sort(({ itemSequenceNumber: a }, { itemSequenceNumber: b }) => a - b).map((item, indexAdditionalData) => {
                                                                        return <AccordionItem>
                                                                            <AccordionHeader title={`Payment detail ${item.itemSequenceNumber}`} />
                                                                            <AccordionPane>
                                                                                <div className='accordion_pane_content' key={`accordion-${item.itemSequenceNumber}`}>
                                                                                    {item.fields.map((field, indexPaymentDetail) => {
                                                                                        if (manageState === TransactionState.Edit) {
                                                                                            return <div className="additional-payment-details">
                                                                                                <div key={indexPaymentDetail * 10} className="additional-payment-textareas">
                                                                                                    {field.key !== "Corp code" && field.key !== getCustomDropdownHeader &&
                                                                                                        <Textarea
                                                                                                            label={field.key}
                                                                                                            value={field.value}
                                                                                                            size='small'
                                                                                                            handleOnChange={(e: Event) => handleOnChangePaymentDetails(indexPaymentDetail, indexAdditionalData, field.id, e)}
                                                                                                            type="text"
                                                                                                            classes='width-300' maxLength={40}
                                                                                                            ariaLabel={`${field.key} for ${item.itemSequenceNumber}`}
                                                                                                        />}
                                                                                                    {field.key === "Corp code" &&

                                                                                                        <Select
                                                                                                            handleOnChange = {(e: Event) => handleOnChangePaymentDetails(indexPaymentDetail, indexAdditionalData, field.id, e)}
                                                                                                            inputId={`select-textInput-account-${field.id}`}
                                                                                                            label={field.key}
                                                                                                            options={getCorpCodeList}
                                                                                                            size="small"
                                                                                                            classes="width-322"
                                                                                                            value={field.value}
                                                                                                            name="select-account-fields-textInput"
                                                                                                        />}
                                                                                                    {field.key === getCustomDropdownHeader &&
                                                                                                        <Select
                                                                                                            handleOnChange={(e: Event) => handleOnChangePaymentDetails(indexPaymentDetail, indexAdditionalData, field.id, e)}
                                                                                                            inputId={`select-textInput-account-${field.id}`}
                                                                                                            label={field.key}
                                                                                                            options={getCustomDropdownList}
                                                                                                            size="small"
                                                                                                            classes="width-322"
                                                                                                            value={field.value}
                                                                                                            name="select-account-fields-textInput"
                                                                                                        />
                                                                                                    }
                                                                                                </div>

                                                                                            </div>
                                                                                        } else {
                                                                                            return <div className='additional-payment-details'>
                                                                                                <div key={indexPaymentDetail * 10}>
                                                                                                    {field.key?.trim() !== "" ?
                                                                                                        <div><p className='data-title'>{field.key}</p></div>
                                                                                                        :
                                                                                                        <></>
                                                                                                    }
                                                                                                    {field.value?.trim() !== "" ?
                                                                                                        <div>
                                                                                                            <p className='exception-data-text'>{field.value}</p>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <></>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        }

                                                                                    })}
                                                                                    {manageState === TransactionState.Edit && item.itemSequenceNumber !== 1 && <div className="additional-payment-delete">
                                                                                        <Button
                                                                                            clickMethod={() => onClickDeletePaymentDetail(item.itemSequenceNumber)}
                                                                                            icon="IconTrash"
                                                                                            size="small"
                                                                                            text="Delete payment detail"
                                                                                            ariaLabel={`Delete payment detail ${item.itemSequenceNumber}`}
                                                                                            variant="secondary"
                                                                                        />
                                                                                    </div>
                                                                                    }
                                                                                </div>

                                                                            </AccordionPane>

                                                                        </AccordionItem>
                                                                    })
                                                                    }
                                                                </Accordion>
                                                                {manageState === TransactionState.Edit && itemData.additional_data.length < 100 && <div className="additional-payment-add">
                                                                    <Button
                                                                        clickMethod={() => onClickAddPaymentDetail()}
                                                                        icon="IconPlus"
                                                                        size="small"
                                                                        text="Add new payment detail"
                                                                        ariaLabel={`Add payment detail`}
                                                                        variant="secondary"
                                                                    />
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </CardContainer>
                                                </div>
                                                : <></>
                                            }
                                            {manageState === TransactionState.Edit && <div className='exception-transaction-submit'>
                                                <Button
                                                    size="medium"
                                                    text="Submit"
                                                    clickMethod={() => handleSubmit()}
                                                    ariaLabel="Submit transaction updates"
                                                />
                                            </div>
                                            }

                                        </>
                                        :
                                        <></>}
                                </div>
                                <div className='enlarged-section'
                                >
                                    {enlargeImage ?
                                        <>
                                            <div className='close-enlarge'>
                                                <button tabIndex={0} autoFocus aria-label='Close enlarged image' onClick={handleCloseEnlargeImage}><IconClose style={{ color: '#007C8C' }} width="24" height="24" ref={closeEnlargeButtonRef} /></button>
                                            </div>
                                            <div>
                                                <AuthenticatedImage
                                                    alt={`Check ${currentItemIndex + 1}, enlarged`}
                                                    className="full-image"
                                                    imageUrl={`${itemData.data[currentItemIndex]?.image}`}
                                                    imageFilename={`${itemData.data[currentItemIndex]?.image}`}
                                                />
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                            </Tile>
                            <div className="custom-pagination-container">
                                <CustomPagination enlargedImageShow={isEnlarge} ExceptionTransaction returnToExceptionShow={isEnlarge} gotoTransaction={true} returnToExceptionSummary={(pageCount - 1) === transaction_current_page} onClickReturnToExceptionSummary={handleReturnToExceptionSummary}>
                                    <ReactPaginate
                                        className={isEnlarge ? 'hide-block' : ''}
                                        nextLabel={<span>Next<span className='page-next-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                                <path d="M2.27024 0.233855C1.91064 -0.045751 1.39068 -0.0203322 1.06023 0.310111C0.702253 0.668092 0.702253 1.24849 1.06023 1.60647L5.45372 5.99996L1.06023 10.3934L0.983977 10.4798C0.704371 10.8394 0.72979 11.3594 1.06023 11.6898C1.41821 12.0478 1.99862 12.0478 2.3566 11.6898L7.39826 6.64814C7.4258 6.62061 7.45122 6.59175 7.47452 6.56179C7.48908 6.54306 7.50282 6.52389 7.51573 6.50434C7.75065 6.14854 7.7115 5.66501 7.39826 5.35178L2.3566 0.310111L2.27024 0.233855Z" fill="#007C8C" />
                                            </svg></span></span>}
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={1}
                                        pageCount={pageCount}
                                        previousLabel={
                                            <span><span className='page-previous-icon'><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                                <path d="M6.93977 0.310111C6.58179 -0.0478692 6.00138 -0.0478692 5.6434 0.310111L0.601737 5.35178C0.5742 5.37932 0.548782 5.40817 0.525481 5.43814C0.245875 5.79774 0.271294 6.3177 0.601737 6.64814L5.6434 11.6898L5.72976 11.7661C6.08936 12.0457 6.60932 12.0203 6.93977 11.6898C7.29775 11.3318 7.29775 10.7514 6.93977 10.3934L2.54628 5.99996L6.93977 1.60647L7.01602 1.52012C7.29563 1.16052 7.27021 0.640555 6.93977 0.310111Z" fill="#007C8C" />
                                            </svg>
                                            </span>&nbsp;Previous</span>
                                        }
                                        pageLinkClassName={"cta cta--text-alternate cta--medium"}
                                        previousClassName=""
                                        previousLinkClassName={"cta cta--text-alternate cta--medium"}
                                        nextClassName=""
                                        nextLinkClassName={"cta cta--text-alternate cta--medium"}
                                        breakLabel="..."
                                        breakClassName={"pagination__ellipses"}
                                        breakLinkClassName={"break-text"}
                                        breakAriaLabels={{
                                            forward: "forward break",
                                            backward: "backward break"
                                        }}
                                        pageClassName={""}
                                        containerClassName={"pagination__list"}
                                        activeClassName={""}
                                        activeLinkClassName={"cta cta--text-alternate cta--medium cta--active"}
                                        renderOnZeroPageCount={null}
                                        disabledLinkClassName={"pagination__prev-next--display"}
                                        disabledClassName={"disable-link-pagination-list"}
                                        initialPage={transaction_current_page}
                                        forcePage={forcedPage}
                                    />
                                </CustomPagination>
                            </div>
                            {!isEnlarge ?
                                <ModalsContainer noGap>
                                    <CustomModal
                                        okType
                                        ModalText="This could be because the system returned an unexpected error or is temporarily unavailable to process your request. Please try again later."
                                        ModalTitle="Unable to save changes"
                                        ModalIsOpen={saveErrorModalOpen}
                                        CloseModal={() => {
                                            setSaveErrorModalOpen(false)
                                        }}
                                    />
                                    <CustomModal
                                        okType 
                                        ModalText="The document amounts for this exception are out of balance. Please try again."
                                        ModalTitle="Exception transaction not saved"
                                        ModalIsOpen={isInBalance}
                                        CloseModal={() => {
                                            setIsInBalance(false)
                                        }}
                                    />
                                    <CustomModal
                                        okType
                                        ModalText={`This transaction is locked because ${showLockedTransactionModal.userName} is using it. Please choose another transaction.`}
                                        ModalTitle='Transaction is currently locked'
                                        ModalIsOpen={showLockedTransactionModal.show}
                                        CloseModal={() => { setShowLockedTransactionModal({ ...showLockedTransactionModal, show: false }) }}
                                    />
                                </ModalsContainer>
                                :
                                <></>
                            }
                        </>
                    </div>
                </div>
            </div>

        </main>
    );
}

export default ExceptionTransactionDetail;
