import requests from "./apiRequest";

export interface CustomAccountField {
    id: number
    fieldName?: string
    fieldSelection: number
    fieldLength: number
}

export interface CustomAmountField {
    id: number
    fieldName?: string
    fieldSelection: number
    fieldLength: number
}

export interface CustomDropdownField {
    id: number
    fieldName: string
    fieldDescription: string
}

export interface CorpCodeField {
    id: number,
    corpCode: string,
    corpCodeLabel: string
}

export interface ExceptionSetupRes {
    id: number,
    lockboxCode: string,
    cutoffTime?: string,
    accountFieldName?: string,
    accountFieldSelection?: number,
    accountFieldLength?: number,
    customAccountFields?: CustomAccountField[],
    amountFieldName?: string,
    amountFieldSelection?: number,
    amountFieldLength?: number,
    customAmountFields?: CustomAmountField[],
    customDropdownLabel?: string,
    customDropdownAccountField?: number,
    customDropdownLength?: number,
    customDropdownFields?: CustomDropdownField[],
    corpCodeLabel?: string,
    corpCodeField?: number,
    corpCodeLength?: number,
    corpCodes?: CustomDropdownField[]
}

export interface CreateCustomAccountField {
    fieldName: string
    fieldSelection: number
    fieldLength: number
}

export interface CreateCustomAmountField {
    fieldName: string
    fieldSelection: number
    fieldLength: number
}

export interface CreateExceptionSetupTextInput {
    lockboxCode: string,
    cutoffTime: string,
    accountFieldName: string,
    accountFieldSelection: number,
    accountFieldLength: number,
    customAccountFields: CreateCustomAccountField[],
    amountFieldName: string,
    amountFieldSelection: number,
    amountFieldLength: number,
    customAmountFields: CreateCustomAmountField[],
    corpCodeLabel?: string,
    corpCodeField?: number,
    corpCodeLength?: number,
}

export interface CreateCustomDropdownField {
    fieldName: string
    fieldDescription: string
}

export interface CreateExceptionSetupCustomDropdown {
    lockboxCode: string,
    cutoffTime: string,
    customDropdownLabel: string,
    customDropdownAccountField: number,
    customDropdownLength: number,
    customDropdownFields: CreateCustomDropdownField[]
}

export interface CreateCorpCodeField {
    fieldName: string,
    fieldSelection: string
}

export interface CreateExceptionSetupCorpCode {
    lockboxCode: string,
    cutoffTime: string,
    corpCodeLabel: string,
    corpCodeField: number,
    corpCodeLength: number,
    corpCodes: CreateCustomDropdownField[]
}

export interface FieldTextInput {
    id: number
    selectVal: string
    inputVal: string
    lengthVal: string
}

export const DefaultAccountFieldTextInput: FieldTextInput[] = [
    { id: 2, selectVal: '1', inputVal: '', lengthVal: '' },
    { id: 3, selectVal: '', inputVal: '', lengthVal: '' }
];

export const DefaultCorpCodeFieldTextInput: FieldTextInput[] = [
    { id: 1, selectVal: '0', inputVal: '', lengthVal: '' },
];

export const DefaultAmountFieldTextInput: FieldTextInput[] = [
    { id: 1, selectVal: '1', inputVal: '', lengthVal: '' },
    { id: 2, selectVal: '', inputVal: '', lengthVal: '' }
];

export interface TextInputListForAccountAmount {
    isDisabled: boolean,
    label: string,
    value:  string
}
export const Select_account_list = [
    {
        isDisabled: false,
        label: 'Not applicable',
        value: ''
    },
    {
        isDisabled: false,
        label: 'Account 1',
        value: '1'
    },
    {
        isDisabled: false,
        label: 'Account 2',
        value: '2'
    },
    {
        isDisabled: false,
        label: 'Account 3',
        value: '3'
    },
    {
        isDisabled: false,
        label: 'Account 4',
        value: '4'
    },
    {
        isDisabled: false,
        label: 'Account 5',
        value: '5'
    },
    {
        isDisabled: false,
        label: 'Account 6',
        value: '6'
    },
    {
        isDisabled: false,
        label: 'Account 7',
        value: '7'
    },
    {
        isDisabled: false,
        label: 'Account 8',
        value: '8'
    },
    {
        isDisabled: false,
        label: 'Account 9',
        value: '9'
    },
    {
        isDisabled: false,
        label: 'Account 10',
        value: '10'
    },
];

export const Select_amount_list = [
    {
        isDisabled: true,
        label: 'Not applicable',
        value: ''
    },
    {
        isDisabled: false,
        label: 'Amount 1',
        value: '1'
    },
    {
        isDisabled: false,
        label: 'Amount 2',
        value: '2'
    },
    {
        isDisabled: false,
        label: 'Amount 3',
        value: '3'
    },
    {
        isDisabled: false,
        label: 'Amount 4',
        value: '4'
    },
    {
        isDisabled: false,
        label: 'Amount 5',
        value: '5'
    },
    {
        isDisabled: false,
        label: 'Amount 6',
        value: '6'
    },
    {
        isDisabled: false,
        label: 'Amount 7',
        value: '7'
    },
    {
        isDisabled: false,
        label: 'Amount 8',
        value: '8'
    },
    {
        isDisabled: false,
        label: 'Amount 9',
        value: '9'
    },
    {
        isDisabled: false,
        label: 'Amount 10',
        value: '10'
    },
];

export const Cutoff_time_options =[
    {
        label: '8:00',
        value: '08:00:00'
    },
    {
        label: '9:00',
        value: '09:00:00'
    },
    {
        label: '10:00',
        value: '10:00:00'
    },
    {
        label: '11:00',
        value: '11:00:00'
    },
    {
        label: '12:00',
        value: '12:00:00'
    },
    {
        label: '13:00',
        value: '13:00:00'
    },
    {
        label: '14:00',
        value: '14:00:00'
    },
];

export interface APIError {
    type: string,
    title: string,
    status: number,
    errors: {
        [key: string]: string[]
    }
}

export const getExceptionSetup = async (lockboxCode: string): Promise<ExceptionSetupRes> => {
    let result = await requests.get(`/ExceptionSetup/${lockboxCode}`);
    return result;
};

export const createExceptionSetup = {
    textInput: async (body: CreateExceptionSetupTextInput) => {
        const { data } = await requests.post("/ExceptionSetup/TextInput", body);
        return data;
    },
    customDropdown: async (body: CreateExceptionSetupCustomDropdown) => {
        const { data } = await requests.post("/ExceptionSetup/CustomDropdown", body);
        return data;
    },
    corpCodes: async (body: CreateExceptionSetupCorpCode) => {
        const { data } = await requests.post("/ExceptionSetup/CorpCodes", body);
        return data;
    }
}
