/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, FormEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom-v6';

import BatchMirror from '../components/BatchMirror';
import ModalsContainer from '../components/ModalsContainer';
import CustomModal from '../components/CustomModal';
import Loader from '../components/Loader';
import Table from '../components/Table';
import { BatchDetailMirror, BatchDetailRes, BatchType, batch_ID, transaction_ID, RefreshBatchDetail, TransactionCurrentPage, userState } from '../atom';
import { useRecoilState } from 'recoil';

import BatchdataDetail from '../API_Integrations/BatchDetail';
import { IDownloadObject } from '../interfaces/IBatch_Detail';
import { useTitle } from '../utils/use-title';

// @ts-ignore
import { Modal, Checkbox, DropdownMenu, DropdownTrigger, DropdownMenuContent, RadioGroup, CheckboxGroup, Button, ErrorSummary  } from '@ux/balance-react';
// @ts-ignore
import { IconCheckmark } from '@ux/balance-icons';

import SearchForm from '../components/SearchForm';
import ClientHeader from '../components/ClientHeader';
import { getDateString } from '../utils/date-utils';
import TransactiondataDetail from '../API_Integrations/TransactionDetail';
interface ErrorMessage { ariaLabel: string, text: string, inputId: string }

function BatchDetail() {

    useTitle("Batch detail");
    const [loading, setLoading] = useState(true);
    const [batchId, setBatchId] = useRecoilState(batch_ID);
    const [transactionId, setTransactionId] = useRecoilState(transaction_ID);
    const [refresh, setRefresh] = useRecoilState(RefreshBatchDetail);
    const [isBatchDetail, setIsBatchDetail] = useState(false);
    const [processingDate, setProcessingDate] = useState(getDateString(new Date()));

    const [modalIsOpenCSV, setModalIsOpenCSV] = useState(false);
    const [modalIsOpenZip, setModalIsOpenZip] = useState(false);
    const [modalIsOpenPDF, setModalIsOpenPDF] = useState(false);
    const [modalPDFstep2, setModalPDFstep2] = useState(false);
    const [notFoundModalOpen, setNotFoundModalOpen] = useState(false);
    const [transactionNotSelected, setTransactionNotSelected] = useState(false);
    const [errorMessagesSummary, seterrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [isvalidDocument, setIsvalidDocument] = useState(true);
    const [isvalidFile, setIsvalidFile] = useState(true);
    const [user, setUser] = useRecoilState(userState);

    //batch number
    const [searchbatchNumber, setsearchbatchNumber] = useState('');
    const HandleOnChangeBatchNumber = (event: Event) => { return setsearchbatchNumber((event.target as HTMLInputElement).value) }

    //Batch detail  mirror
    const [batchdetailMirror, setBatchdetailMirror] = useRecoilState(BatchDetailMirror);
    const [data_batch_detail, setData_batch_detail] = useRecoilState(BatchDetailRes);
    const [batch_type, setBatch_type] = useRecoilState(BatchType);
    const [transaction_current_page, setTransaction_current_page] = useRecoilState(TransactionCurrentPage);

    //radios
    const [downloadObject, setDownloadObject] = useState<IDownloadObject>({ documents: false, checks: false , fileType: '' });

    //checkbox
    const [selectedRows, setSelectedRows] = useState(Array(data_batch_detail.length).fill(false));
    const [checkedAll, setCheckedAll] = useState(false);
    const errorSummaryRef = useRef<HTMLElement | null>(null);
    //skip checkbox
    const [skipRows, setSkipRows] = useState<boolean[]>(Array(data_batch_detail.length).fill(false));
    const [skipLoading, setSkipLoading] = useState(false);


    useEffect(() => {
    }, [batchId]);

    useEffect(() => {
        if (isBatchDetail) {
            const handleBrowserHistory = () => {
                const currentPath = window.location.pathname;
                const pathId = currentPath.replace('/batch-detail/', '');
                const idNumber = parseInt(pathId, 10);
                setBatchId(idNumber);
            }
            window.onpopstate = handleBrowserHistory;
            return () => {
                window.onpopstate = null;
            }
        }
    }, [searchbatchNumber, isBatchDetail]);

    useEffect(() => {
        getBatchDetail(batchId);
        return () => {
            setData_batch_detail([]);
            setBatch_type('');
            setBatchdetailMirror({});
        }
    }, [batchId, setData_batch_detail]);

    useEffect(() => {
        if (refresh !== false) {
            getBatchDetail(batchId);
            setRefresh(false);
        }
    }, [refresh]);

    const navigate = useNavigate();

    //batch detail API
    const getBatchDetail = async (batchid: number) => {
        setLoading(true);
        let result = await BatchdataDetail.details(batchid);
        if (result !== undefined) {
            console.log("result", result)
            setData_batch_detail(result.items);
            setProcessingDate(result.depositDate);
            setSelectedRows(Array(result.items.length).fill(false));
            setBatch_type(result.batchType);
            setBatchdetailMirror({
                depositDate: result.depositDateFormatted,
                batchNumber: result.batchNumber,
                transactions: result.transactionCountFormatted,
                batchTotal: result.batchTotalFormatted,
                lockboxCode: result.lockboxCode,
                totalBatchesForProcessingDate: result.totalBatchesForProcessingDate
            });
            setRefresh(false);
            setLoading(false);
        } else {
            setProcessingDate(getDateString(new Date()));
            setLoading(false);
            console.log('items not found');
        }
    }

    useEffect(() => {
    }, [data_batch_detail]);

    //
    const HandleSearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        if (searchbatchNumber !== '') {
            setIsBatchDetail(true);
            BatchdataDetail
                .lookup(batchdetailMirror.lockboxCode ?? "", processingDate, Number(searchbatchNumber))
                .then(result => {
                    setBatchId(result.batchId)
                    navigate(`/batch-detail/${result.batchId}`)
                    setLoading(false);
                })
                .catch(_ => {
                    setNotFoundModalOpen(true);
                    setLoading(false);
                })

        } else {
            setIsBatchDetail(true);
            let searchBatch = batchId;
            getBatchDetail(searchBatch);
            navigate(`/batch-detail/${searchBatch}`);
        }
    }
  

    //toCSV
    const handleCSV = () => {
        let selectedTransactions = data_batch_detail
            .filter((_, index) => selectedRows[index])
            .map(value => value.id);

        BatchdataDetail.generateReport(batchId, {
            fileType: 'csv',
            includeChecks: downloadObject.checks,
            includeDocuments: downloadObject.documents,
            selectedTransactions: selectedTransactions
        })

        setModalIsOpenCSV(true);
    }
    //toPDF
    const handlePDF = () => {
        let selectedTransactions = data_batch_detail
            .filter((_, index) => selectedRows[index])
            .map(value => value.id);

        BatchdataDetail.generateReport(batchId, {
            fileType: 'pdf',
            includeChecks: downloadObject.checks,
            includeDocuments: downloadObject.documents,
            selectedTransactions: selectedTransactions
        })

        setModalIsOpenPDF(true);
    }
    //to zip
    const handleZip = () => {
        let selectedTransactions = data_batch_detail
            .filter((_, index) => selectedRows[index])
            .map(value => value.id);

        BatchdataDetail.generateReport(batchId, {
            fileType: 'zip',
            includeChecks: downloadObject.checks,
            includeDocuments: downloadObject.documents,
            selectedTransactions: selectedTransactions
        })

        setModalIsOpenZip(true);
    }

    const handleSelectRow = (index: number) => {
        console.log(selectedRows)
        var selRows = [...selectedRows];
        selRows[index] = !selRows[index];
        setSelectedRows(selRows);
        if (selRows.some((element: boolean) => !element)) {
            setCheckedAll(false);
        }
        else {
            setCheckedAll(true);
        }
    }

    const handleSelectAll = () => {
        setCheckedAll(!checkedAll);
        if (checkedAll) {
            var setAllRowsFalse = selectedRows.map((function () { return !1 }));
            setSelectedRows(setAllRowsFalse);
        }
        else {
            var setAllRowsTrue = selectedRows.map((function () { return !0 }));
            setSelectedRows(setAllRowsTrue);
        }
    }

    const handleGotoTransaction = (propsGoto: any, index: number) => {
        setTransactionId(propsGoto.transactionId);
        setTransaction_current_page(index);
        navigate(`/transaction-detail/${propsGoto.transactionId}`);
    }

    const handleOnChangeFileType = (fileVal: string) => {
        console.log('change file to = ' + fileVal);
        setDownloadObject({ ...downloadObject, fileType: fileVal });
    }

    const handleOnChangeDocuments = (event: Event, documentName: string) => {
        const newVal = (event.target as HTMLInputElement).checked;
        if (downloadObject.hasOwnProperty(documentName)) {
            setDownloadObject({ ...downloadObject, [documentName]: newVal });
        }
    };
    const validateForm = () => {
        const errors: ErrorMessage[] = [];

        if (!downloadObject.checks && !downloadObject.documents) {
            errors.push({ ariaLabel: "Document type is required", text: "Choose document type", inputId: 'documentSelect' });
            setIsvalidDocument(false);
        } else {
            setIsvalidDocument(true);
        }

        if (downloadObject.fileType === "") {
            errors.push({ ariaLabel: "File type is required", text: "Choose file type", inputId: 'fileSelect' });
            setIsvalidFile(false);
        } else {
            setIsvalidFile(true);
        }

        seterrorMessagesSummary(errors);
        return errors;
    }
    const handleDownloadTransactions = () => { 
        const selectedIds = data_batch_detail
            .filter((batch, index) => selectedRows[index])
            .map((transaction) => transaction.id);

        if(selectedIds.length > 0 ) {
            let catchError = validateForm();

            if (catchError.length === 0) {
                if(downloadObject.fileType === "PDF") {
                handlePDF();
            } else if((downloadObject.fileType === "Zip")) {
                handleZip();
            } else if((downloadObject.fileType === "CSV")) {
                handleCSV();
            }}
        } else {
            setTransactionNotSelected(true);
        }
    }

    const handleSkipRow = (index: number) => {
        const updatedSkipStatus = !skipRows[index];  // Toggle the skip status
    
        // Update local skip state without affecting the backend
        setSkipRows(prev => {
            const newSkipRows = [...prev];
            newSkipRows[index] = updatedSkipStatus;
            return newSkipRows;
        });
    
        console.log(`Transaction ${data_batch_detail[index].transactionId} skip status updated locally.`);
    };

    const handleSkipTransactions = async () => {
        const transactionsToUpdate = data_batch_detail
            .filter((_, index) => skipRows[index])  // Filter transactions that are marked for skipping
            .map(transaction => transaction.transactionId);  // Get transaction IDs
        
        if (transactionsToUpdate.length > 0) {
            setSkipLoading(true);
            
            try {
                // Make API call for each transaction that needs to be skipped
                for (const transactionId of transactionsToUpdate) {
                    if (!transactionId) {
                        console.error("Transaction not found: ", transactionId);
                        return;
                    }
                    await TransactiondataDetail.skipTransaction(transactionId);
                    console.log(`Transaction ${transactionId} skipped.`);
                }
                await getBatchDetail(batchId);

                // Reset selected rows and skip rows to ensure checkboxes are cleared
                setSelectedRows(Array(data_batch_detail.length).fill(false));
                setSkipRows(Array(data_batch_detail.length).fill(false));
                
            } catch (error) {
                console.error("Error skipping transactions:", error);
            } finally {
                setSkipLoading(false);
            }
        } else {
            console.log("No transactions selected for skipping.");
        }
    };

    const columns = [
        {
          headerId: 'select',
          headerLabel: 'Select all',
          type: 'component',
          rowCellTableRowComponentsArrayIndex: 0,
          headerCellTableRowComponentsArrayIndex: 1,
          disableSorting: true
        },
        {
          headerId: 'sequenceNumber',
          headerLabel: 'Transaction',
          type: 'component',
          rowCellTableRowComponentsArrayIndex: 3
        },
        {
          headerId: 'remitterName',
          headerLabel: 'Remitter'
        },
        {
          headerId: 'amountFormatted',
          headerLabel: 'Amount'
        },
        {
          headerId: 'serialNumber',
          headerLabel: 'Serial no.'
        },
        {
          headerId: 'routingNumber',
          headerLabel: 'Routing no.'
        },
        {
          headerId: 'accountNumber',
          headerLabel: 'Account no.',
        },
        {
          headerId: 'itemReviewed',
          headerLabel: 'Reviewed',
          type: 'component',
          rowCellTableRowComponentsArrayIndex: 2
        }
      ];
  
      if (user?.isBanker) {
        columns.push({
          headerId: 'skip',
          headerLabel: 'Skip',
          type: 'component',
          rowCellTableRowComponentsArrayIndex: 4
        });
      }

    const rowComponents = [
        (props: any) => (
          <div className='center-select'>
            <Checkbox
              isChecked={selectedRows[props.row.index]}
              handleOnChange={() => handleSelectRow(props.row.index)}
              label={`Select transaction ${props.row.original.sequenceNumber}`}
              labelIsHidden
              inputId={`input-${props.row.original.id}`}
            />
          </div>
        ),
        (props: any) => (
          <div className='table-select-all'>
            <span>Select all</span>
            <Checkbox
              isChecked={checkedAll}
              handleOnChange={handleSelectAll}
              label="Select all transactions"
              labelIsHidden
              inputId="selectAllTransactionsBatchDetail"
            />
          </div>
        ),
        (props: any) => (
          <div className='review-items'>
            {props.row.original.itemReviewed ? (
              <div className='center-link'><IconCheckmark style={{ color: '#417514' }} width="24" height="24" /></div>
            ) : null}
          </div>
        ),
        (props: any) => (
          <div className='center-link'>
            <Button
              variant="text-primary"
              clickMethod={() => handleGotoTransaction(props.row.original, props.row.index)}
              text={props.row.original.sequenceNumber}
            />
          </div>
        )
      ];
  
      if (user?.isBanker) {
        rowComponents.push((props: any) => (
          <div className='center-checkbox'>
            <Checkbox
              isChecked={skipRows[props.row.index]}
              inputId={`skip-${props.row.original.id}`}
              handleOnChange={() => handleSkipRow(props.row.index)}
              label={`Skip transaction ${props.row.original.sequenceNumber}`}
              labelIsHidden
            />
          </div>
        ));
      }
    
    
    

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={true} />
            {!loading ?
            <>
            <BatchMirror
                batch_details={true}
                lockbox_code={batchdetailMirror.lockboxCode}
                batch_ID={batchdetailMirror.batchNumber}
                date={batchdetailMirror.depositDate}
                batchCount={batchdetailMirror.totalBatchesForProcessingDate}
                batchNumber={batchdetailMirror.batchNumber}
                transaction={batchdetailMirror.transactions}
                total={batchdetailMirror.batchTotal}
                totalBatchesForProcessingDate={batchdetailMirror.totalBatchesForProcessingDate}
            />
            <SearchForm
                batchNumber={searchbatchNumber}
                handleOnChangeBatchNumber={HandleOnChangeBatchNumber}
                handleSearch={HandleSearch}
            >
                {data_batch_detail && data_batch_detail.length > 0 ?
                <DropdownMenu>
                    {(user?.isBanker &&
                    <Button
                    classes="skip-transactions-button"
                    size="medium"
                    text="Skip Transactions"
                    clickMethod={handleSkipTransactions}
                    style={{ marginRight: '5px' }} 
                    disabled={skipLoading || skipRows.every(row => !row)}  
                    />)}
                    <DropdownTrigger
                        text="Download transactions"
                        ariaLabel="Download transactions menu"
                        dataTestId="download-transactions-menu"
                        variant="alternate"
                        size="medium"
                        classes="highlighted-active"
                    />
                    <DropdownMenuContent>
                        <p className='title'>Choose download options</p>
                        <div className='dropdown-divider'></div>
                        {errorMessagesSummary.length !== 0 &&
                            <ErrorSummary
                                errors={errorMessagesSummary}
                                ref={errorSummaryRef}
                            />
                        }
                        <CheckboxGroup
                            id="documentSelect"
                            classes="form-control"
                            legendLabel="Document type"
                            groupFormControlId="documentSelection"
                            errorMessages={["Select a document type to continue."]}
                            isValid={isvalidDocument}
                            checkboxItems={[
                                {
                                    isChecked: downloadObject.checks,
                                    handleOnChange: (e: Event) => handleOnChangeDocuments(e, 'checks'),
                                    label: "Checks",
                                    size: "small",
                                    
                                },
                                {
                                    isChecked: downloadObject.documents,
                                    handleOnChange: (e: Event) => handleOnChangeDocuments(e, 'documents'),
                                    label: "Documents",
                                    size: "small"
                                }
                            ]}
                        />
                        <div className='dropdown-divider'></div>
                        <div className='radio-groups'>
                            <RadioGroup
                                groupFormControlId="fileSelect"
                                legendLabel="File type"
                                name="radio-group-file"
                                size="small"
                                errorMessages={["Select a file type to continue."]}
                                isValid={isvalidFile}
                                radioItems={[
                                    {
                                        handleOnChange: () => handleOnChangeFileType("CSV"),
                                        inputId: 'radio-file-type-csv',
                                        isChecked: downloadObject.fileType === "CSV",
                                        label: 'CSV',
                                        value: "CSV",
                                        size: 'small'
                                    },
                                    {
                                        handleOnChange: () => handleOnChangeFileType("PDF"),
                                        inputId: 'radio-file-type-pdf',
                                        isChecked: downloadObject.fileType === "PDF",
                                        label: 'PDF',
                                        value: "PDF",
                                        size: 'small'
                                    },
                                    {
                                        handleOnChange: () => handleOnChangeFileType("Zip"),
                                        inputId: 'radio-file-type-zip',
                                        isChecked: downloadObject.fileType === "Zip",
                                        label: 'Zip file',
                                        value: "Zip",
                                        size: 'small'
                                    }
                                ]}
                            />
                        </div>
                        <Button
                            classes="download-button"
                            size="medium"
                            text="Download"
                            clickMethod={() => { handleDownloadTransactions() }}
                            
                        />
                    </DropdownMenuContent>
                </DropdownMenu>
                : 
                <></>
                }
            </SearchForm>

            <div className='batch-detail-table'>
                        {data_batch_detail && data_batch_detail.length > 0 ?
                            <>
                                <Table
                                    caption={`Batch ${batchdetailMirror.batchNumber} detail on ${batchdetailMirror.depositDate}`}
                                    columnData={columns}
                                    data={data_batch_detail}
                                    showSupplementalStatus
                                    supplementalStatus={`${selectedRows.filter(val => val === true).length} of ${data_batch_detail.length} ${data_batch_detail.length > 1 ? 'rows' : 'row'} selected for download`}
                                    tableRowComponents={rowComponents}
                                />
                            </>
                            : <p>No result found.</p>
                        }
            </div>
            <ModalsContainer noGap>
                <CustomModal
                    okType
                    ModalText="Please select one or more transactions before proceeding to download."
                    ModalTitle="No transactions selected"
                    ModalIsOpen={transactionNotSelected}
                    CloseModal={() => {
                        setTransactionNotSelected(false);
                    }}
                />
                <CustomModal
                    okType
                    ModalText="The requested batch number was not found."
                    ModalTitle="Batch number not found"
                    ModalIsOpen={notFoundModalOpen}
                    CloseModal={() => {
                        setNotFoundModalOpen(false)
                    }}
                />
                <CustomModal
                    downloadReports
                    showClose
                    ModalText="Your PDF file has been requested and will be available in Reports."
                    ModalTitle="Request submitted"
                    ModalIsOpen={modalIsOpenPDF}
                    CloseModal={() => {
                        setModalIsOpenPDF(false)
                    }}
                />
                <CustomModal
                    downloadReports
                    showClose
                    ModalText="Your zip file has been requested and will be available in Reports."
                    ModalTitle="Request submitted"
                    ModalIsOpen={modalIsOpenZip}
                    CloseModal={() => {
                        setModalIsOpenZip(false)
                    }}
                />
                <CustomModal
                    downloadReports
                    ModalText="Your CSV file has been requested and will be available in Reports."
                    ModalTitle="Request submitted"
                    ModalIsOpen={modalIsOpenCSV}
                    CloseModal={() => {
                        setModalIsOpenCSV(false)
                    }}
                />
            </ModalsContainer>
            </>
            :
            <Loader />
        }
        </main>
    )
}

export default BatchDetail;