/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom-v6";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState, Client_code, Exception_Banker_CurrentTab, Exception_Client_CurrentTab, exception_lockbox_code, exception_batch_ID } from "../atom";
import { unlockExceptionTransaction, UnlockExceptionTransaction } from "../API_Integrations/exceptionTransactionDetail"

function ExceptionBreadcrumbs(props: any) {
  const userType = useRecoilValue(userState);
  const clientCode = useRecoilValue(Client_code);
  const exception_lockboxCode = useRecoilValue(exception_lockbox_code);
  const exception_batchId = useRecoilValue(exception_batch_ID);
  const setBankerExceptionCurrentTab = useSetRecoilState(Exception_Banker_CurrentTab);
  const setClientExceptionCurrentTab = useSetRecoilState(Exception_Client_CurrentTab);
  const current_page_transaction_id = props.exceptionTransactionId;
  let navigate = useNavigate();
  let location = useLocation();
  let path = location.pathname;

  useEffect(() => {
  }, [location]);

  const runUnlockExceptionTransactionApi = async () => {

     const body: UnlockExceptionTransaction = {
        exceptionTransactionId: current_page_transaction_id
     }
      if (current_page_transaction_id !== undefined) {
          await unlockExceptionTransaction(current_page_transaction_id, body);
      }   
  }


  const toExceptionSummary = () => {
    if (userType.isBanker) {
      runUnlockExceptionTransactionApi();
      navigate(`/banker-lockbox-summary/${clientCode}`);
      setBankerExceptionCurrentTab('EXCEPTIONMANAGEMENT');
    } else {
      runUnlockExceptionTransactionApi(); 
      navigate('/dashboard-summary');
      setClientExceptionCurrentTab('EXCEPTIONMANAGEMENT');
    }
  }

  const toExceptionBatchSummary = () => {
    if (exception_lockboxCode) {
      runUnlockExceptionTransactionApi();
      navigate(`/exception-batch-summary/${exception_lockboxCode}`);
    }
  }

  const toExceptionBatchDetail = () => {
    if (exception_batchId) {
      runUnlockExceptionTransactionApi();
      navigate(`/exception-batch-detail/${exception_batchId}`);
    }
  }

  return (
    <nav className="breadcrumbs">
      {
        (path.includes('/exception-daily-summary')) ?
          <span><span className="parent"><button onClick={toExceptionSummary}>Exception summary</button><span className="slash">/</span></span><span className="current" >Daily decision summary</span></span>
          : (path.includes('/exception-daily-decision')) ?
            <span><span className="parent"><button onClick={toExceptionSummary}>Exception summary</button><span className="slash">/</span></span><span className="current" >Daily decision detail</span></span>
            :
            (path.includes('exception-batch-summary/')) ?
              <span><span className="parent"><button onClick={toExceptionSummary}>Exception summary</button><span className="slash">/</span></span><span className="parent" ><span className="current" >Exception batch summary</span></span></span>
              : (path.includes('/exception-batch-detail')) ?
                <span><span className="parent"><button onClick={toExceptionSummary}>Exception summary</button><span className="slash">/</span></span><span className="parent" ><button onClick={toExceptionBatchSummary}>Exception batch summary</button><span className="slash">/</span></span><span className="current" >Exception batch detail</span></span>
                :
                (path.includes('/exception-transaction-detail')) ?
                  <span><span className="parent"><button onClick={toExceptionSummary}>Exception summary</button><span className="slash">/</span></span><span className="parent" ><button onClick={toExceptionBatchSummary}>Exception batch summary</button><span className="slash">/</span></span><span className="parent" ><button onClick={toExceptionBatchDetail}>Exception batch detail</button><span className="slash">/</span></span><span className="current" >Exception transaction detail</span></span>
                  :
                  <></>
      }
    </nav>
  );
}

export default ExceptionBreadcrumbs;
