import requests from "./apiRequest";

export interface TransactionReport {
    includeChecks: boolean
    includeDocuments: boolean
}

const TransactiondataDetail = {
    details: async (ID: number) => {
        try {
            let result = await requests.get(`/transactions/${ID}`);
            return result;
        } catch (error) {
        console.log('error from transactions api integration: ', error);
        }
    },
    generateReport: async (ID: number, body: TransactionReport) => {
        await requests.post(`/transactions/${ID}/report`, body);
    },
    addReview: async (ID: number) => {
        try {
            await requests.postParams(`transactions/${ID}/review`);
        } catch (error) {
            console.log('error from transaction add review api integration: ', error);
        }
    },
    deleteReview: async (ID: number) => {
        try {
            await requests.delete(`transactions/${ID}/review`);
        } catch (error) {
            console.log('error from transaction delete review api integration: ', error);
        }
    },
    skipTransaction: async (transactionId: number) => {
        console.log("we are in skipBatch api integration")
        try {
            let result = await requests.post(`/transactions/${transactionId}/skip`, {});
            return result;
        } catch (error) {
            console.log('Error skipping batch: ', error);
            throw error;
        }
    },
    unskipTransaction: async (transactionId: number) => {
        console.log("we are in unskipBatch api integration")
        try {
            let result = await requests.delete(`/transactions/${transactionId}/unskip`);
            return result;
        } catch (error) {
            console.log('Error skipping batch: ', error);
            throw error;
        }
    }
}
export default TransactiondataDetail;
