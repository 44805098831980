import requests from "./apiRequest";

export interface BatchLookup {
    batchId: number
}

export interface BatchDetailReportRequest {
    selectedTransactions?: (number | undefined)[]
    includeChecks: boolean
    includeDocuments: boolean
    fileType: "pdf" | "csv" | "zip"
}

const BatchdataDetail = {
    details: async (ID: number) => {
        try {
            let result = await requests.get(`/batches/${ID}`);
            return result;
        } catch (error) {
            console.log('error from lockbox api integration: ', error);
        }
    },
    lookup: async (lockboxCode: string, processingDate: string, batchNumber: number): Promise<BatchLookup> => {
        let result = await requests.get("/batches?lockboxCode=" + lockboxCode + "&processingDate=" + processingDate + "&batchNumber=" + batchNumber)
        return result;
    },
    generateReport: async (batchId: number, body: BatchDetailReportRequest) => {
        let result = await requests.post("batches/" + batchId + "/report", body);
        return result;
    },
}
export default BatchdataDetail;
