/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Accordion, AccordionHeader, AccordionItem, AccordionPane, Button, Modal, Tile } from '@ux/balance-react';
import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom-v6';
// @ts-ignore
import { IconClose } from '@ux/balance-icons';
import { useRecoilState } from 'recoil';
import UTIF from 'utif';
import BatchdataDetail from '../API_Integrations/BatchDetail';
import CorrespondenceData from '../API_Integrations/Correspondence';
import TransactiondataDetail from '../API_Integrations/TransactionDetail';
import { BatchDetailRes, BatchType, IsEnlarged, LastFocusedImageBtn, TransactionAdditionalDetailsRes, TransactionCheckIndex, TransactionCorrespondenceDataRes, TransactionCurrentPage, TransactionDetailMirror, TransactionItemsDataRes, batch_ID, transaction_ID } from '../atom';
import AuthenticatedImage from '../components/AuthenticatedImage';
import BatchMirror from '../components/BatchMirror';
import CardContainer from '../components/CardContainer';
import CardData from '../components/CardData';
import ClientHeader from '../components/ClientHeader';
import CustomModal from '../components/CustomModal';
import CustomPagination from '../components/CustomPagination';
import ImageViewer from '../components/Imageviewer';
import Loader from '../components/Loader';
import ModalsContainer from '../components/ModalsContainer';
import { IBatch_Detail_Res } from '../interfaces/IBatch_Detail';
import { useTitle } from '../utils/use-title';
import { replaceImages } from '../utils/image-utils';

interface PageChangeData {
    selected: number
}

interface BatchDetail {
    id: number
    transactionId: number
    batchType: string
    depositDate: string
    depositDateFormatted: string
    paymentMethod: string
    machineNumber: number
    batchNumber: number
    ingestionBatchNumber: number
    lockboxCode: string
    transactionCount: number
    transactionCountFormatted: string
    correspondenceCount: number
    correspondenceCountFormatted: string
    processedOn: string
    processedOnFormatted: string
    items: IBatch_Detail_Res[]
}

function TransactionDetail() {
    const [loading, setLoding] = useState(true);
    const [isEnlarge, setIsEnlarge] = useRecoilState(IsEnlarged);
    const [transactionId, setTransactionId] = useRecoilState(transaction_ID);
    const [batch_type, setBatch_type] = useRecoilState(BatchType);
    const [transactiondetailMirror, setTransactiondetailMirror] = useRecoilState(TransactionDetailMirror);
    const [item_data, setItem_data] = useRecoilState(TransactionItemsDataRes);
    const [correspondence_data, setCorrespondence_data] = useRecoilState(TransactionCorrespondenceDataRes);
    const [additional_data, setAdditional_data] = useRecoilState(TransactionAdditionalDetailsRes);
    const [retailType, setRetailType] = useState(true);
    const [transaction_current_page, setTransaction_current_page] = useRecoilState(TransactionCurrentPage);
    const [data_batch_detail, setData_batch_detail] = useRecoilState(BatchDetailRes);
    const [batchId, setBatchId] = useRecoilState(batch_ID);
    const [notFoundModalOpen, setNotFoundModalOpen] = useState(false);
    const [pageCount, setPageCount] = useState(data_batch_detail.length);
    const [remountKey, setRemountKey] = useState(0);
    useTitle("Transaction detail");
    const initialHeadingRef = useRef<HTMLHeadingElement | null>(null);

    //images
    const [currentItemIndex, setcurrentItemIndex] = useRecoilState(TransactionCheckIndex);
    const [forceImagePagination, setForceImagePagination] = useState(false);
    const [enlargeImage, setEnlargeImage] = useState(false);
    const [showDetailData, setShowDetailData] = useState(true);
    const [closeButtonFocus, setCloseButtonFocus] = useState(false);
    const [lastFocusedBtn, setLastFocusedBtn] = useRecoilState(LastFocusedImageBtn);
    const imageViewerRefs: { [key: string]: {iconButtonRef:  React.RefObject<HTMLButtonElement>, imageButtonRef: React.RefObject<HTMLButtonElement> } }= {
        'img1': {iconButtonRef:  useRef<HTMLButtonElement | null>(null), imageButtonRef:  useRef<HTMLButtonElement | null>(null)},
        'img2': {iconButtonRef:  useRef<HTMLButtonElement | null>(null), imageButtonRef:  useRef<HTMLButtonElement | null>(null)}
    }
    const closeEnlargeButtonRef = useRef<HTMLButtonElement | null>(null);

    const [modalIsOpenPDF, setModalIsOpenPDF] = useState(false);
    const [modalPDFstep2, setModalPDFstep2] = useState(false);
    //radios
    const [selectedRadio, setSelectedRadio] = useState('');

    const navigate = useNavigate();

    useEffect(() => {

    }, [transactionId]);

    useEffect(() => {
        replaceImages();
    }, [isEnlarge, enlargeImage, loading, transactionId, currentItemIndex, transaction_current_page]);

    useEffect(() => {
        replaceImages();
    }, []);

    //Transactions API
    const getTransactionDetail = async (transactionid: number) => {
        setLoding(true);
        let result = await TransactiondataDetail.details(transactionid)
        if (result !== undefined) {
            setShowDetailData(result.isDetail)
            setItem_data(result.items);
            setAdditional_data(result.detailData);
            if (result.lockboxType !== 'Retail') {
                setRetailType(false);
            }
            setTransactiondetailMirror({
                lockboxName: result.lockboxName,
                lockboxCode: result.lockboxCode,
                site: result.siteName,
                depositDate: result.depositDate,
                batchNumber: result.batchNumber,
                status: result.status,
                batchItemSequenceNumber: result.batchItemSequenceNumber
            });
            setBatch_type("Transaction");
            navigate(`/transaction-detail/${transactionid}`);
            setLoding(false);
            setRemountKey((prevKey) => prevKey + 1);
        } else {
            setNotFoundModalOpen(true);
            setLoding(false);
        }
    }

    const handleAddReviewTransaction = (code: number) => {
        const reviewCode = code;
        TransactiondataDetail.addReview(reviewCode);
    }

    const getCorrespondenceDetail = async (transactionid: number) => {
        setLoding(true);
        let result = await CorrespondenceData.details(transactionid)
        if (result !== undefined) {
            setCorrespondence_data({
                correspondenceId: result.correspondenceId,
                filename: result.filename
            });
            setTransactiondetailMirror({
                lockboxName: result.lockboxName,
                lockboxCode: result.lockboxCode,
                site: result.siteName,
                depositDate: result.depositDate,
                batchNumber: result.batchNumber,
                status: result.status,
                batchItemSequenceNumber: result.batchItemSequenceNumber
            });
            setBatch_type('Correspondence')
            navigate(`/transaction-detail/${transactionid}`);
            setLoding(false);
            setRemountKey((prevKey) => prevKey + 1);
        } else {
            setNotFoundModalOpen(true);
            setLoding(false);
        }
    }

    useEffect(() => {
        initialHeadingRef.current?.focus();
    }, [remountKey]);

    useEffect(() => {
    }, [currentItemIndex]);

    const handlePDF = () => {
        setModalIsOpenPDF(true);
    }

    const getBatchDetail = async (batchID: number, selectedPage: number) => {
        const result: BatchDetail = await BatchdataDetail.details(batchID);
        if (result.items !== null) {
            const copyBatchDetail = result.items;
            const batch_type = result.batchType;
            const selected_index = selectedPage;
            if (selected_index >= 0 && copyBatchDetail !== undefined) {
                const transaction_id = copyBatchDetail[selected_index].transactionId;
                if (batch_type === "Transaction" && transaction_id !== undefined
                ) {
                    getTransactionDetail(transaction_id);
                    handleAddReviewTransaction(transaction_id);
                } else if (batch_type === 'Correspondence' && transaction_id !== undefined) {
                    getCorrespondenceDetail(transaction_id);
                } else {
                    setNotFoundModalOpen(true);
                    setLoding(false);
                }

            }
        } else {
            setNotFoundModalOpen(true);
            setLoding(false);
        }

    }

    const handlePageClick = async (data: PageChangeData) => {
        setcurrentItemIndex(0);
        setLoding(true);
        if (data.selected !== undefined && batchId > 0) {
            await getBatchDetail(batchId, data.selected);
            setForceImagePagination(true);
        } else {
            setNotFoundModalOpen(true);
            setLoding(false);
        }
    }

    const handleImagePageClick = async (item: PageChangeData) => {
        setLoding(true);
        if (item.selected !== undefined || forceImagePagination) {
            setcurrentItemIndex(item.selected);
            setForceImagePagination(false);
            setLoding(false);
        }
    }

    const handleHeadingFocus = (ref: React.RefObject<HTMLHeadingElement>) => {
        if(!closeButtonFocus) {
            initialHeadingRef.current = ref.current;
        }
    }

    const handleCloseEnlargeImage = () => {
        setIsEnlarge(false);
        setEnlargeImage(false);
        setCloseButtonFocus(true);
    }

    useEffect(() => {
        if (enlargeImage) {
            closeEnlargeButtonRef.current?.focus();
        } else {
            for (const id in lastFocusedBtn) {
                if (lastFocusedBtn[id] === 'icon') {
                    imageViewerRefs[id].iconButtonRef.current?.focus();
                } else if (lastFocusedBtn[id] === 'image') {
                    imageViewerRefs[id].imageButtonRef.current?.focus();
                }
            }
        }
    }, [enlargeImage, lastFocusedBtn]);

    const handleOpenEnlargeImage = (id: string) => {
        setLastFocusedBtn((prev) => ({...lastFocusedBtn, [id]: null}));
        setEnlargeImage(true);
        setIsEnlarge(true);
    }

    const generateReport = () => {
        TransactiondataDetail.generateReport(transactionId, {
            includeChecks: selectedRadio === 'Data and check image' || selectedRadio === 'Data, check image and docs',
            includeDocuments: selectedRadio === 'Data and docs' || selectedRadio === 'Data, check image and docs'
        })
    }

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={true} />
            <div className='loader-section'>
                <Loader transactionLoader isLoading={loading} />
                <div className={loading ? 'custom-loader-hidden' : 'custom-loader-visible'}>
                    <BatchMirror
                        transaction_details={true}
                        onHeadingFocus={handleHeadingFocus}
                        lockbox_code={transactiondetailMirror.lockboxCode}
                        lockbox_name={transactiondetailMirror.lockboxName}
                        batchNumber={transactiondetailMirror.batchNumber}
                        batchItemSequenceNumber={transactiondetailMirror.batchItemSequenceNumber}
                        status={transactiondetailMirror.status}
                        site={transactiondetailMirror.site}
                        depositDate={transactiondetailMirror.depositDate} />
                    {(!isEnlarge) ?
                        <ModalsContainer>
                            <CustomModal
                                ButtonTitle='Download transaction as PDF'
                                ModalIsOpen={modalIsOpenPDF}
                                OpenModal={handlePDF}
                                GoToNext={() => { setModalPDFstep2(true); generateReport() }}
                                CloseModal={() => { setModalPDFstep2(false); setModalIsOpenPDF(false); }}
                                isPDF={true}
                                isPDFstep2={modalPDFstep2}
                                ModalSTextStep2='Your PDF has been requested and will be available in Reports.'
                                radioItems={
                                    [
                                        {
                                            handleOnChange: (event: Event) => {
                                                setSelectedRadio((event.target as HTMLInputElement).value);
                                            },
                                            inputId: 'radio-input-1',
                                            isChecked: selectedRadio === 'Data and check image',
                                            label: 'Data and check image',
                                            value: 'Data and check image',
                                            size: "small"
                                        },
                                        {
                                            handleOnChange: (event: Event) => {
                                                setSelectedRadio((event.target as HTMLInputElement).value);
                                            },
                                            inputId: 'radio-input-2',
                                            isChecked: selectedRadio === 'Data and docs',
                                            label: 'Data and docs',
                                            value: 'Data and docs',
                                            size: "small"
                                        },
                                        {
                                            handleOnChange: (event: Event) => {
                                                setSelectedRadio((event.target as HTMLInputElement).value);
                                            },
                                            inputId: 'radio-input-3',
                                            isChecked: selectedRadio === 'Data, check image and docs',
                                            label: 'Data, check image and docs',
                                            value: 'Data, check image and docs',
                                            size: "small"
                                        }
                                    ]
                                }

                            />
                        </ModalsContainer>
                        :
                        <></>
                    }
                    <div className='transaction-tile-container'>
                        <>

                            <Tile
                                classes=""
                                dataTestId={null}
                                isPadded
                            >
                                
                                    <div className={isEnlarge ? 'hide-block' : ''}>
                                        {(item_data.length > 0 && batch_type !== 'Correspondence') ?
                                            <>
                                                {((item_data[currentItemIndex]?.itemType === 'Document') || (item_data[currentItemIndex]?.itemType === 'Payment' && item_data[currentItemIndex]?.paymentMethod === "Check")) ?

                                                    <div className={(item_data[currentItemIndex]?.itemType === 'Document') ? 'related-documents-container' : 'inner-container'}>
                                                        {(item_data[currentItemIndex]?.itemType === 'Document') ?
                                                            <div>
                                                                <p className='card-title'>Related document(s)</p>
                                                            </div>
                                                            : (item_data[currentItemIndex]?.itemType === 'Payment' && item_data[currentItemIndex]?.paymentMethod === "Check") ?
                                                                <div className='card'>
                                                                    <p className='card-title'>Check Data</p>
                                                                    <CardData title='Payor name' text={item_data[currentItemIndex]?.remitterName} />
                                                                    <CardData title='Amount' text={item_data[currentItemIndex]?.amountFormatted} />
                                                                    <CardData title='Routing number' text={item_data[currentItemIndex]?.routingNumber} />
                                                                    <CardData title='Account number' text={item_data[currentItemIndex]?.accountNumber} />
                                                                    <CardData title='Serial number' text={item_data[currentItemIndex]?.serialNumber} />
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        <div className='image-card'>

                                                        <ImageViewer
                                                            imgSource={`${item_data[currentItemIndex]?.imageUrl}`}
                                                            altImageText={(item_data[currentItemIndex]?.itemType === 'Document') ? `related document ${currentItemIndex + 1}` : (item_data[currentItemIndex]?.itemType === 'Payment' && item_data[currentItemIndex]?.paymentMethod === "Check") ? `check ${currentItemIndex + 1}` : 'doc image'}
                                                            id="img1"
                                                            onClickEnlarge={() => { handleOpenEnlargeImage("img1") }}
                                                            imageButtonRef={imageViewerRefs["img1"]?.imageButtonRef}
                                                            iconButtonRef={imageViewerRefs["img1"]?.iconButtonRef}>
                                                                <div id='test-related-check' className='image-viewer-pagination'>
                                                                    <CustomPagination gotoTransaction={false}>
                                                                        <ReactPaginate
                                                                            className={isEnlarge ? 'hide-block' : ''}
                                                                            nextLabel={<span>Next<span className='page-next-icon'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                                                                    <path d="M2.27015 0.233855C1.91055 -0.045751 1.39059 -0.0203322 1.06014 0.310111C0.702161 0.668092 0.702161 1.24849 1.06014 1.60647L5.45363 5.99996L1.06014 10.3934L0.983886 10.4798C0.70428 10.8394 0.729698 11.3594 1.06014 11.6898C1.41812 12.0478 1.99852 12.0478 2.3565 11.6898L7.39817 6.64814C7.42571 6.62061 7.45112 6.59175 7.47442 6.56179C7.48899 6.54306 7.50273 6.52389 7.51563 6.50434C7.75056 6.14854 7.7114 5.66501 7.39817 5.35178L2.3565 0.310111L2.27015 0.233855Z" fill="#707070" />
                                                                                </svg></span></span>}
                                                                            onPageChange={handleImagePageClick}
                                                                            pageRangeDisplayed={3}
                                                                            marginPagesDisplayed={1}
                                                                            pageCount={item_data.length}
                                                                            previousLabel={
                                                                                <span><span className='page-previous-icon'><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                                                                    <path d="M6.93985 0.310111C6.58187 -0.0478692 6.00146 -0.0478692 5.64348 0.310111L0.601818 5.35178C0.574281 5.37932 0.548862 5.40817 0.525561 5.43814C0.245955 5.79774 0.271374 6.3177 0.601818 6.64814L5.64348 11.6898L5.72984 11.7661C6.08944 12.0457 6.6094 12.0203 6.93985 11.6898C7.29783 11.3318 7.29783 10.7514 6.93985 10.3934L2.54636 5.99996L6.93985 1.60647L7.0161 1.52012C7.29571 1.16052 7.27029 0.640555 6.93985 0.310111Z" fill="#707070" />
                                                                                </svg>
                                                                                </span>&nbsp;Previous</span>
                                                                            }
                                                                            pageLinkClassName={"cta cta--text-alternate cta--medium"}
                                                                            previousClassName=""
                                                                            previousLinkClassName={"cta cta--text-alternate cta--medium"}
                                                                            nextClassName=""
                                                                            nextLinkClassName={"cta cta--text-alternate cta--medium"}
                                                                            breakLabel="..."
                                                                            breakClassName={"pagination__ellipses"}
                                                                            breakLinkClassName={"break-text"}
                                                                            breakAriaLabels={{
                                                                                forward: "forward break",
                                                                                backward: "backward break"
                                                                            }}
                                                                            pageClassName={""}
                                                                            containerClassName={"pagination__list"}
                                                                            activeClassName={""}
                                                                            activeLinkClassName={"cta cta--text-alternate cta--medium cta--active"}
                                                                            renderOnZeroPageCount={null}
                                                                            disabledLinkClassName={"pagination__prev-next--display"}
                                                                            disabledClassName={"disable-link-pagination-list"}
                                                                            initialPage={currentItemIndex}
                                                                            forcePage={forceImagePagination ? 0 : undefined}
                                                                        />
                                                                    </CustomPagination>
                                                                </div>
                                                            </ImageViewer>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                                }

                                                {(item_data[currentItemIndex]?.itemType === 'Payment' && item_data[currentItemIndex]?.paymentMethod === "ElectronicPayment") ?
                                                    <CardContainer>
                                                        <div className='card'>
                                                            <p className='card-title'>Electronic payment</p>
                                                            <CardData title='Amount' text={item_data[currentItemIndex]?.amountFormatted} />
                                                            <CardData title='Payment detail' text={item_data[currentItemIndex]?.accountNumber} />
                                                        </div>
                                                    </CardContainer>
                                                    :
                                                    <></>
                                                }
                                                  {(showDetailData && additional_data.length > 0 && item_data[currentItemIndex]?.paymentMethod !== "ElectronicPayment") ?
                                                    <div className=''>
                                                        <div className='card-divider'></div>
                                                        <CardContainer additional_card>
                                                            <div className='additional-card'>
                                                                <p className='card-title'>Additional payment details</p>
                                                                {!retailType ?
                                                                    <div className='additional-details-note'>
                                                                        <p>Note: The information below may not be directly related to the image above.</p>
                                                                    </div>
                                                                    : <></>
                                                                }
                                                                <div className='additional-details-accordions'>
                                                                    <Accordion
                                                                        setOpen={[]}
                                                                        showExpandCollapse
                                                                    >
                                                                        {additional_data.map((item, index) => {
                                                                            return <AccordionItem>
                                                                                <AccordionHeader title={`Detail ${item.itemSequenceNumber}`} />
                                                                                <AccordionPane>
                                                                                    <div className='accordion_pane_content' key={item.itemSequenceNumber}>
                                                                                        {item.fields.map((field, index) => {
                                                                                            return <div className='additional-details'>
                                                                                                <div key={index * 10}>
                                                                                                    {field.key?.trim() !== "" ?
                                                                                                        <div><p className='data-title'>{field.key}</p></div>
                                                                                                        :
                                                                                                        <></>
                                                                                                    }
                                                                                                    {field.value?.trim() !== "" ?
                                                                                                        <div>
                                                                                                            <p className='data-text'>{field.value}</p>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <></>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                </AccordionPane>
                                                                            </AccordionItem>
                                                                        })
                                                                        }</Accordion>
                                                                </div>
                                                            </div>
                                                        </CardContainer>
                                                    </div>
                                                    : <></>
                                                }

                                            </>
                                            : (batch_type === 'Correspondence') ?
                                                <div className='related-documents-container'>
                                                    <div>
                                                        <p className='card-title'>Related document(s)</p>
                                                    </div>
                                                    <div className='image-card'>
                                                    <ImageViewer
                                                        correspondenceImage
                                                        imgSource={`${correspondence_data.filename}`}
                                                        altImageText={`related document ${currentItemIndex + 1}`} 
                                                        id='img2' 
                                                        onClickEnlarge={() => { handleOpenEnlargeImage("img2") }}
                                                        imageButtonRef={imageViewerRefs["img2"]?.imageButtonRef}
                                                        iconButtonRef={imageViewerRefs["img2"]?.iconButtonRef} />
                                                    </div>
                                                </div>
                                                :
                                                <></>}
                                    </div>
                                <div className='enlarged-section'
                                >
                                    {enlargeImage ?
                                        <>
                                            <div className='close-enlarge'>
                                                <button tabIndex={0} autoFocus aria-label='Close enlarged image' onClick={handleCloseEnlargeImage}><IconClose style={{ color: '#007C8C' }} width="24" height="24" ref={closeEnlargeButtonRef} /></button>
                                            </div>
                                            <div>
                                                <AuthenticatedImage
                                                    alt={item_data[currentItemIndex]?.paymentMethod === "Check" ? `check ${currentItemIndex + 1}, enlarged` : `related document ${currentItemIndex + 1}, enlarged`}
                                                    className="full-image"
                                                    imageUrl={`${item_data[currentItemIndex]?.imageUrl}` }
                                                    imageFilename={`${item_data[currentItemIndex]?.filename}`}
                                                />
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                            </Tile>
                            <div>
                                <CustomPagination enlargedImageShow={isEnlarge} gotoTransaction={true}>
                                <ReactPaginate
                                    className={isEnlarge ? 'hide-block' : ''}
                                    nextLabel={<span>Next<span className='page-next-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                            <path d="M2.27024 0.233855C1.91064 -0.045751 1.39068 -0.0203322 1.06023 0.310111C0.702253 0.668092 0.702253 1.24849 1.06023 1.60647L5.45372 5.99996L1.06023 10.3934L0.983977 10.4798C0.704371 10.8394 0.72979 11.3594 1.06023 11.6898C1.41821 12.0478 1.99862 12.0478 2.3566 11.6898L7.39826 6.64814C7.4258 6.62061 7.45122 6.59175 7.47452 6.56179C7.48908 6.54306 7.50282 6.52389 7.51573 6.50434C7.75065 6.14854 7.7115 5.66501 7.39826 5.35178L2.3566 0.310111L2.27024 0.233855Z" fill="#007C8C" />
                                        </svg></span></span>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel={
                                        <span><span className='page-previous-icon'><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                            <path d="M6.93977 0.310111C6.58179 -0.0478692 6.00138 -0.0478692 5.6434 0.310111L0.601737 5.35178C0.5742 5.37932 0.548782 5.40817 0.525481 5.43814C0.245875 5.79774 0.271294 6.3177 0.601737 6.64814L5.6434 11.6898L5.72976 11.7661C6.08936 12.0457 6.60932 12.0203 6.93977 11.6898C7.29775 11.3318 7.29775 10.7514 6.93977 10.3934L2.54628 5.99996L6.93977 1.60647L7.01602 1.52012C7.29563 1.16052 7.27021 0.640555 6.93977 0.310111Z" fill="#007C8C" />
                                        </svg>
                                        </span>&nbsp;Previous</span>
                                    }
                                    pageLinkClassName={"cta cta--text-alternate cta--medium"}
                                    previousClassName=""
                                    previousLinkClassName={"cta cta--text-alternate cta--medium"}
                                    nextClassName=""
                                    nextLinkClassName={"cta cta--text-alternate cta--medium"}
                                    breakLabel="..."
                                    breakClassName={"pagination__ellipses"}
                                    breakLinkClassName={"break-text"}
                                    breakAriaLabels={{
                                        forward: "forward break",
                                        backward: "backward break"
                                    }}
                                    pageClassName={""}
                                    containerClassName={"pagination__list"}
                                    activeClassName={""}
                                    activeLinkClassName={"cta cta--text-alternate cta--medium cta--active"}
                                    renderOnZeroPageCount={null}
                                    disabledLinkClassName={"pagination__prev-next--display"}
                                    disabledClassName={"disable-link-pagination-list"}
                                    initialPage={transaction_current_page}
                                />
                            </CustomPagination>
                            </div>

                            <div>
                                {(!isEnlarge) ?
                                        
                                        <div>
                                            <Modal
                                                isOpen={notFoundModalOpen}
                                                ariaLabel="Transaction not found"
                                                closeMethod={() => setNotFoundModalOpen(false)}
                                                modalCTAs={<Button clickMethod={() => setNotFoundModalOpen(false)} dataTestId="modal_close_button" text="Ok" variant="primary" size='small' />}
                                                modalTitle=""
                                            >
                                                <h5>Transaction number not found</h5>
                                                <p
                                                    className="text-style-body-2"
                                                    style={{
                                                        marginTop: '1rem',
                                                        maxWidth: '100%'
                                                    }}
                                                >
                                                    <span>The requested transaction number was not found.</span>
                                                </p>
                                            </Modal>
                                        </div>
                                    : <></>
                                }
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </main>
    )
}
export default TransactionDetail;
